import { Button } from "react-admin";
import ContentDownload from "@material-ui/icons/GetApp";
import { Link } from "@material-ui/core";
import React from "react";
import { get } from "lodash";

const DownloadButton = ({ record, source = "media" }) => {
  const url = record ? get(record, `${source}.file.path`) : null;
  if (!url) {
    return null;
  }
  return (
    <Button
      color="primary"
      label={"app.download"}
      component={Link}
      href={url}
      target="_blank"
    >
      <ContentDownload />
    </Button>
  );
};
export default DownloadButton;
