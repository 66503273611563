import { Collapse, Divider, Typography } from "@material-ui/core";
import React, { Fragment } from "react";

import { FieldTitle } from "react-admin";
import { ReferenceListField } from "ra-friendsofbabba";
import { makeStyles } from "@material-ui/core/styles";
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
  toggle: {
    marginTop: theme.spacing(1),
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));
const CollapsableReferenceListField = ({
  label,
  savePreference = true,
  ...props
}) => {
  const preferenceKey = `collapsable-reference-list-field-${
    props?.record?.id || 0
  }.${props.source}`;

  const [open, setOpen] = React.useState(
    savePreference ? localStorage.getItem(preferenceKey) === "true" : true
  );
  const handleToggle = useCallback(() => {
    setOpen(!open);
    if (savePreference) {
      localStorage.setItem(preferenceKey, !open);
    }
  }, [open, preferenceKey, savePreference]);
  const classes = useStyles();
  if (!props.record?.id) {
    return null;
  }
  return (
    <Fragment>
      <Typography
        variant="subtitle1"
        onClick={handleToggle}
        className={classes.toggle}
      >
        <FieldTitle label={label} {...props} />
        {open ? " -" : " +"}
      </Typography>
      <Divider className={classes.divider} />
      <Collapse in={open}>
        <ReferenceListField {...props} addLabel={false} />
      </Collapse>
    </Fragment>
  );
};

export default CollapsableReferenceListField;
