import { Create, Edit, useTranslate } from "react-admin";

import { HumanResourceForm } from "../component/form";

const HumanResourceTitle = ({ record }) => {
  const translate = useTranslate();
  const prefix = translate("resources.human-resources.name", {
    smart_count: 1,
  });
  return `${prefix} ${record?.description}`;
};
const HumanResourceCreate = (props) => (
  <Create {...props}>
    <HumanResourceForm />
  </Create>
);

const HumanResourceEdit = (props) => (
  <Edit {...props} title={<HumanResourceTitle />}>
    <HumanResourceForm />
  </Edit>
);

const config = {
  create: HumanResourceCreate,
  edit: HumanResourceEdit,
};

export default config;
