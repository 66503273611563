import { Group, GroupItem } from "ra-friendsofbabba";

import PublicSpacesMediaField from "../../field/PublicSpacesMediaField";
import React from "react";

const PublicSpaceGroup3 = ({
  create,
  visible,
  fieldLabel,
  fieldSource,
  ...props
}) => (
  <Group wrapper {...props}>
    <Group fullWidth>
      <GroupItem lg={12} md={12} sm={12} xs={12}>
        <PublicSpacesMediaField
          source={fieldSource("media")}
          foreignKey={fieldSource("id")}
          fullWidth
        />
      </GroupItem>
    </Group>
  </Group>
);
export default PublicSpaceGroup3;
