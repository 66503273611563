import React from "react";
import { ReferenceCheckboxGroupInput } from "ra-friendsofbabba";

const format = (values) => values?.map(({ province }) => province);
const parse = (values) => values?.map((province) => ({ province }));

const ProvincesInput = (props) => (
  <ReferenceCheckboxGroupInput
    {...props}
    parse={parse}
    format={format}
    reference="municipalities/provinces"
  />
);

export default ProvincesInput;
