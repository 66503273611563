import { CronMonthInput, OwnerTypeInput } from "../input";
import {
  DebouncedTextInput,
  Group,
  GroupItem,
  Toolbar,
  useFieldLabel,
  useSaveMutation,
} from "ra-friendsofbabba";
import { SimpleForm, required } from "react-admin";

import { useMemo } from "react";

const ExecutiveProjectCronPhaseActionForm = (props) => {
  const save = useSaveMutation({ ...props });
  const fieldLabel = useFieldLabel(props);
  const disabled = useMemo(() => props?.record?.is_static, [props?.record]);
  const min = useMemo(
    () => (props?.record?.phase > 1 ? props?.record?.min || 1 : 1),
    [props?.record]
  );
  return (
    <SimpleForm
      {...props}
      toolbar={
        <Toolbar
          backTab={3}
          backReferenceTarget="executive_project_id"
          backReference="executive-projects"
        />
      }
      save={save}
    >
      <Group fullWidth>
        <GroupItem lg={8} md={10} sm={12}>
          <DebouncedTextInput
            source="title"
            maxLength={150}
            disabled={disabled}
            validate={required()}
          />
        </GroupItem>
        <GroupItem lg={10} md={12} sm={12}>
          <DebouncedTextInput
            multiline
            source="brief_description"
            disabled={disabled}
            maxLength={500}
          />
        </GroupItem>
        <GroupItem lg={10} md={12} sm={12}>
          <DebouncedTextInput
            multiline
            source="excepted_result"
            disabled={disabled}
            maxLength={500}
          />
        </GroupItem>
        <GroupItem lg={12} md={12} sm={12}>
          <OwnerTypeInput source="owner_types" disabled={disabled} />
        </GroupItem>
        <GroupItem lg={5} md={5} sm={8}>
          <CronMonthInput
            source="month"
            label={fieldLabel("month.long")}
            min={min}
          />
        </GroupItem>
      </Group>
    </SimpleForm>
  );
};
export default ExecutiveProjectCronPhaseActionForm;
