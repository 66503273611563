import { ArrayInput, SimpleFormIterator, required } from "react-admin";

import React from "react";
import RichTextInput from "../../RichTextInput";

const TextBlocks = ({ field, values, translate, ...props }) => {
  const additionalProps = {};
  if (field.is_required) {
    additionalProps.validate = required();
  }
  return (
    <ArrayInput
      {...additionalProps}
      source={field.code}
      label={field.name}
      helperText={translate("resources.posts.fields.text_blocks.helper_text")}
    >
      <SimpleFormIterator
        disableReordering
        TransitionProps={{ enter: false, exit: false }}
      >
        <RichTextInput
          label=""
          source="text_value"
          toolbar={["bold", "italic", "underline", "image"]}
          fullWidth
          validate={required()}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default TextBlocks;
