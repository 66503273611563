import { DateTimeInput } from "ra-friendsofbabba";
import React from "react";
import { required } from "react-admin";

const DateTime = ({ field, values, translate, ...props }) => (
  <DateTimeInput
    {...props}
    source={field.code}
    label={field.name}
    validate={field?.is_required && required()}
  />
);

export default DateTime;
