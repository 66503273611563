import { Create, Edit, useTranslate } from "react-admin";

import { ExecutiveProjectPublicSpaceForm } from "../component/form";

const ExecutiveProjectPublicSpaceTitle = ({ record }) => {
  const translate = useTranslate();
  const prefix = translate("resources.executive-project-public-spaces.name", {
    smart_count: 1,
  });
  return `${prefix} ${record?.name}`;
};
const ExecutiveProjectPublicSpaceCreate = (props) => (
  <Create {...props}>
    <ExecutiveProjectPublicSpaceForm />
  </Create>
);

const ExecutiveProjectPublicSpaceEdit = (props) => (
  <Edit {...props} title={<ExecutiveProjectPublicSpaceTitle />}>
    <ExecutiveProjectPublicSpaceForm />
  </Edit>
);

const config = {
  create: ExecutiveProjectPublicSpaceCreate,
  edit: ExecutiveProjectPublicSpaceEdit,
};
export default config;
