import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  required,
} from "react-admin";
import {
  DebouncedNumberInput,
  DebouncedTextInput,
  Group,
  GroupItem,
  ImageInput,
} from "ra-friendsofbabba";

import React from "react";
import { makeStyles } from "@material-ui/core";
import { url } from "../../../../utility/validation";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
}));
const SimpleUserList = ({ field, values, translate, ...props }) => {
  const classes = useStyles();
  const additionalProps = {};
  if (field?.is_required) {
    additionalProps.validate = required();
  }
  return (
    <ArrayInput
      {...props}
      {...additionalProps}
      source={field.code}
      label={field.name}
    >
      <SimpleFormIterator
        disableReordering
        TransitionProps={{ enter: false, exit: false }}
      >
        <FormDataConsumer>
          {({ formData, scopedFormData, getSource, ...rest }) => (
            <Group wrapper {...rest}>
              <Group>
                <GroupItem lg={4}>
                  <DebouncedTextInput
                    label={translate(
                      "resources.posts.fields.simple_user_list.name"
                    )}
                    source={getSource("name")}
                    validate={required()}
                  />
                </GroupItem>
                <GroupItem lg={4}>
                  <DebouncedTextInput
                    label={translate(
                      "resources.posts.fields.simple_user_list.city"
                    )}
                    source={getSource("city")}
                    validate={required()}
                  />
                </GroupItem>
                <GroupItem lg={2}>
                  <DebouncedNumberInput
                    label={translate(
                      "resources.posts.fields.simple_user_list.age"
                    )}
                    source={getSource("age")}
                    validate={required()}
                    className={classes.input}
                  />
                </GroupItem>
              </Group>
              <Group>
                <GroupItem lg={8} md={10} sm={12} xs={12}>
                  <DebouncedTextInput
                    label={translate(
                      "resources.posts.fields.simple_user_list.about"
                    )}
                    source={getSource("about")}
                    multiline
                    maxLength={300}
                    validate={required()}
                    className={classes.input}
                  />
                </GroupItem>
              </Group>
              <Group>
                <GroupItem lg={8} md={10} sm={12} xs={12}>
                  <DebouncedTextInput
                    label={translate(
                      "resources.posts.fields.simple_user_list.link"
                    )}
                    source={getSource("link")}
                    validate={[required(), url()]}
                    className={classes.input}
                  />
                </GroupItem>
              </Group>
              <Group>
                <GroupItem lg={8} md={10} sm={12} xs={12}>
                  <ImageInput
                    label={translate(
                      "resources.posts.fields.simple_user_list.media"
                    )}
                    source={getSource("media")}
                    accept="image/*"
                    title="filename"
                  />
                </GroupItem>
              </Group>
            </Group>
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};
export default SimpleUserList;
