import {
  FormTab,
  Group,
  GroupItem,
  ValidationSummary,
  useTabVisibility,
  useWorkflowInput,
} from "ra-friendsofbabba";
import React, { useMemo } from "react";

import DirtyFormAlert from "../../alert/DirtyFormAlert";
import { ExecutiveProjectPublicSpacesField } from "../../field";
import { PublicSpaceSurveyInput } from "../../input";
import { TextInput } from "react-admin";
import useFormPermission from "../../input/useFormPermission";

const SURVEY_ITEMS = [
  ["is_public_space_requiring_law_upgrades", "public_space_law_upgrade_notes"],
  [
    "is_public_space_requiring_building_upgrades",
    "public_space_building_upgrade_notes",
  ],
  [
    "is_public_space_requiring_equipment_upgrades",
    "public_space_equipment_upgrade_notes",
  ],
];
const ExecutiveProjectTab2 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(2);
  const { disabled } = useWorkflowInput({ ...props, source: "public_space" });
  const hasFormPermission = useFormPermission();
  const hasPermission = useMemo(
    () => hasFormPermission("public_space"),
    [hasFormPermission]
  );
  return (
    <FormTab {...props} label={fieldLabel("tabs.section_2")}>
      {visible && <ValidationSummary />}
      {visible && <DirtyFormAlert />}
      <Group fullWidth>
        <GroupItem lg={8} md={10} sm={12}>
          <TextInput source="project.public_space.name" disabled />
        </GroupItem>
        <GroupItem lg={8} md={10} sm={12}>
          <TextInput source="project.public_space.address" disabled />
        </GroupItem>
      </Group>
      {SURVEY_ITEMS.map((item, index) => (
        <PublicSpaceSurveyInput
          key={index}
          fullWidth
          fieldLabel={fieldLabel}
          disabled={disabled || !hasPermission}
          source={item[0]}
          notesSource={item[1]}
        />
      ))}
      {visible && (
        <ExecutiveProjectPublicSpacesField
          fullWidth
          source="executive_project_public_spaces"
          fieldLabel={fieldLabel}
          disabled={disabled || !hasPermission}
        />
      )}
    </FormTab>
  );
};
export default ExecutiveProjectTab2;
