import { createContext, useEffect, useState } from "react";

import useLayoutData from "./useLayoutData";

export const LayoutDataContext = createContext({
  postTypes: [],
});

export const LayoutDataProvider = ({ apiUrl, children }) => {
  const [_data, setData] = useState({});
  const { loading, loaded, data } = useLayoutData({ apiUrl });

  useEffect(() => setData(data), [data]);

  return (
    <LayoutDataContext.Provider
      value={{
        loading,
        loaded,
        postTypes: _data?.postTypes || [],
      }}
    >
      {children}
    </LayoutDataContext.Provider>
  );
};
