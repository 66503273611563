import React from "react";
import { Typography } from "@material-ui/core";
import { get } from "lodash";

const formatter = (props, lang = "it-IT") => Intl.NumberFormat(lang, props);

const MoneyField = ({ record, source, basePath, ...props }) => {
  const price = parseFloat(get(record, source));
  return (
    <Typography {...props}>
      {formatter({
        style: "currency",
        currency: "EUR",
      }).format(price)}
    </Typography>
  );
};
export default MoneyField;
