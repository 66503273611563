import {
  DebouncedDateInput,
  FormTab,
  Group,
  GroupItem,
  TransactionLogsField,
  TransactionNotesInput,
  TransactionNotesIsPrivateInput,
  useTabVisibility,
} from "ra-friendsofbabba";

import DirtyFormAlert from "../../alert/DirtyFormAlert";
import { LockOpen } from "@material-ui/icons";
import { PermissionsInput } from "../../input";
import React from "react";

const ProjectTab5 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(5);

  return (
    <FormTab
      {...props}
      label={fieldLabel("tabs.section_5")}
      icon={<LockOpen />}
    >
      {visible && <DirtyFormAlert />}
      {visible && <PermissionsInput source="permissions" fullWidth />}
      {visible && (
        <Group fullWidth>
          <GroupItem lg={6} md={8} sm={12} xs={12}>
            <DebouncedDateInput
              source="expire_at"
              helperText={fieldLabel("expire_at.help")}
            />
          </GroupItem>
        </Group>
      )}
      {visible && <TransactionNotesInput source="notes" fullWidth />}
      {visible && <TransactionNotesIsPrivateInput source="is_private" />}
      {visible && <TransactionLogsField source="logs" fullWidth />}
    </FormTab>
  );
};
export default ProjectTab5;
