import {
  Checkbox,
  Divider,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import { Group, GroupItem, GroupTitle, useFieldLabel } from "ra-friendsofbabba";
import React, { useCallback, useMemo } from "react";
import { useForm, useFormState } from "react-final-form";
import { useQueryWithStore, useTranslate } from "ra-core";

import { get } from "lodash";

const useStyles = makeStyles((theme) => ({
  field: {
    paddingLeft: theme.spacing(2),
  },
  subfield: {
    width: 350,
    display: "inline-block",
    paddingLeft: theme.spacing(2),
  },
}));
const PermissionsInput = ({ disabled, ...props }) => {
  const { loaded, data } = useQueryWithStore({
    type: "get",
    resource: `${props?.resource}/permissionable-fields`,
  });
  const fields = useMemo(() => (loaded ? data?.fields : {}), [loaded, data]);
  const fieldLabel = useFieldLabel({ ...props });
  const classes = useStyles();
  const formState = useFormState();
  const form = useForm();
  const translate = useTranslate();
  const permissions = get(formState.values, props.source, []);
  const checked = useMemo(
    () =>
      Object.keys(fields).reduce(
        (data, field) => ({
          ...data,
          // Add fields (eventually) contained in the section.
          ...get(fields, field).reduce(
            (subfields, subfield) => ({
              ...subfields,
              [subfield]:
                permissions.find((p) => p.name === `${field}.${subfield}`) !==
                undefined,
            }),
            {}
          ),
          // Add section field with relative state.
          [field]: permissions.find((p) => p.name === field) !== undefined,
        }),
        {}
      ),
    [permissions, fields]
  );
  const handleCheck = useCallback(
    (field, subfield) => {
      let perms = [...permissions];
      let key = subfield !== false ? `${field}.${subfield}` : field;
      if (perms.find((p) => p.name === key)) {
        perms = perms.filter((p) => p.name !== key);
        if (subfield === false) {
          // Remove all fields associated to the section.
          const subfields = get(fields, field).map((s) => `${field}.${s}`);
          perms = perms.filter((p) => subfields.indexOf(p.name) === -1);
        } else {
          const subfields = get(fields, field).map((s) => `${field}.${s}`);
          const selection = perms.filter(
            (p) => subfields.indexOf(p.name) !== -1
          );
          if (subfields.length > 0 && selection.length === 0) {
            // Remove section because there are no subfields selected.
            perms = perms.filter((p) => p.name !== field);
          }
        }
      } else {
        perms.push({ name: key });
        if (subfield === false) {
          // I'm adding a section field, so I also load all the fields
          const subfields = get(fields, field);
          perms = perms.concat(
            subfields.map((subfield) => ({ name: `${field}.${subfield}` }))
          );
        } else if (!perms.find((p) => p.name === field)) {
          // Add section field, user has clicked directly on a field
          perms.push({ name: field });
        }
      }
      form.change(props.source, perms);
    },
    [permissions, form, fields, props.source]
  );

  return (
    <Group wrapper>
      <GroupTitle
        title={fieldLabel(props.source)}
        subTitle={translate(
          `resources.${props.resource}.fields.${props.source}.help`
        )}
      />
      <Group>
        {Object.keys(fields).map((field) => (
          <GroupItem lg={12} md={6} sm={12} xs={12} key={field}>
            <FormControlLabel
              className={classes.field}
              control={
                <Checkbox
                  checked={get(checked, field, false)}
                  name={field}
                  onChange={handleCheck.bind(this, field, false)}
                />
              }
              label={<strong>{fieldLabel(field)}</strong>}
            />
            <Divider />
            {get(fields, field).map((subfield) => (
              <FormControlLabel
                key={subfield}
                className={classes.subfield}
                control={
                  <Checkbox
                    checked={get(checked, subfield, false)}
                    name={subfield}
                    onChange={handleCheck.bind(this, field, subfield)}
                  />
                }
                label={fieldLabel(subfield)}
              />
            ))}
            {fields[field].length > 0 && <Divider />}
          </GroupItem>
        ))}
      </Group>
    </Group>
  );
};

export default PermissionsInput;
