const { ReferenceAutocompleteArrayInput } = require("ra-friendsofbabba");

const PostsRelatedInput = (props) => (
  <ReferenceAutocompleteArrayInput
    {...props}
    reference="related-posts"
    optionText="title"
    filter={{ not_in: props?.record?.id }}
  />
);

export default PostsRelatedInput;
