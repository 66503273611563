import { BooleanInput, email, useNotify, usePermissions } from "react-admin";
import {
  DebouncedDateInput,
  DebouncedTextInput,
  Group,
  GroupItem,
  GroupTitle,
  MediaInput,
} from "ra-friendsofbabba";
import React, { useCallback, useMemo } from "react";

import MapInput from "../MapInput";
import PublicSpaceHandlerInput from "../PublicSpaceHandlerInput";
import PublicSpaceTypeCustomInput from "../PublicSpaceTypeCustomInput";
import PublicSpaceTypeInput from "../PublicSpaceTypeInput";
import { get } from "lodash";
import moment from "moment";
import { useFormState } from "react-final-form";

const PublicSpaceGroup1 = ({
  create,
  visible,
  fieldLabel,
  fieldSource,
  ...props
}) => {
  const { values } = useFormState({ subscription: { values: true } });
  const isPrivate = useMemo(
    () => get(values, fieldSource("handler")) === "private",
    [fieldSource, values]
  );
  const notify = useNotify();
  const handleRejection = useCallback(
    () => notify("ra.message.file_rejected", { type: "error" }),
    [notify]
  );
  const today = useMemo(() => new Date(), []);
  const { loaded, permissions } = usePermissions();
  const isAdmin = useMemo(
    () => loaded && permissions(["admin", "region"]),
    [loaded, permissions]
  );
  // Agli amministratori è concesso poter proseguire anche se la data non è valida.
  const futureDate = useCallback(
    (message = "ra.validation.future_date") =>
      (value) =>
        !isAdmin && moment(value).isBefore(today) && message,
    [today, isAdmin]
  );
  return (
    <Group wrapper {...props}>
      <GroupTitle
        title={fieldLabel("general")}
        subTitle={fieldLabel("general.help")}
      />
      <Group fullWidth>
        <GroupItem lg={4} md={4}>
          <DebouncedTextInput
            source={fieldSource("name")}
            label={fieldLabel("name")}
            maxLength={200}
            isRequired
          />
        </GroupItem>
        <GroupItem lg={4} md={4}>
          <PublicSpaceTypeInput
            source={fieldSource("public_space_type_id")}
            label={fieldLabel("public_space_type_id")}
            isRequired
          />
        </GroupItem>
        <GroupItem lg={4} md={4}>
          <PublicSpaceTypeCustomInput
            source={fieldSource("public_space_type_custom")}
            label={fieldLabel("public_space_type_custom")}
            dep={fieldSource("public_space_type_id")}
            maxLength={100}
            isRequired
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={8} md={10} sm={12}>
          <DebouncedTextInput
            source={fieldSource("address")}
            label={fieldLabel("address")}
            maxLength={100}
            isRequired
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={12} md={12} sm={12} xs={12}>
          <BooleanInput
            source={fieldSource("is_compliant")}
            label={fieldLabel("is_compliant")}
            isRequired
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={12} md={12} sm={12}>
          <MapInput
            source={fieldSource("map")}
            label={fieldLabel("map")}
            getSource={fieldSource}
            isRequired
          />
        </GroupItem>
      </Group>

      <GroupTitle
        title={fieldLabel("management")}
        subTitle={fieldLabel("management.help")}
      />
      <Group>
        <GroupItem lg={12} md={12} sm={12}>
          <PublicSpaceHandlerInput
            label={fieldLabel("handler")}
            source={fieldSource("handler")}
            helperText={fieldLabel("handler.help")}
            isRequired
          />
        </GroupItem>
      </Group>
      {isPrivate && (
        <Group wrapper fullWidth>
          <GroupTitle title={fieldLabel("private")} />
          <Group>
            <GroupItem lg={8} md={8} sm={12}>
              <DebouncedTextInput
                source={fieldSource("private_name")}
                label={fieldLabel("private_name")}
                helperText={fieldLabel("private_name.help")}
                maxLength={100}
                isRequired
              />
            </GroupItem>
            <GroupItem lg={4} md={4} sm={12}>
              <DebouncedTextInput
                source={fieldSource("private_code")}
                label={fieldLabel("private_code")}
                helperText={fieldLabel("private_code.help")}
                maxLength={20}
                isRequired
              />
            </GroupItem>
          </Group>
          <Group>
            <GroupItem lg={12} md={12} sm={12}>
              <DebouncedTextInput
                source={fieldSource("private_rep_fullname")}
                label={fieldLabel("private_rep_fullname")}
                helperText={fieldLabel("private_rep_fullname.help")}
                maxLength={100}
                isRequired
              />
            </GroupItem>
            <GroupItem>
              <DebouncedDateInput
                source={fieldSource("private_expiry_date")}
                label={fieldLabel("private_expiry_date")}
                helperText={fieldLabel("private_expiry_date.help")}
                validate={futureDate()}
                isRequired
              />
            </GroupItem>
          </Group>
          <Group>
            <GroupItem>
              <DebouncedTextInput
                source={fieldSource("private_email")}
                label={fieldLabel("private_email")}
                maxLength={100}
                validate={email()}
                isRequired
              />
            </GroupItem>
            <GroupItem>
              <DebouncedTextInput
                source={fieldSource("private_phone")}
                label={fieldLabel("private_phone")}
                maxLength={100}
                isRequired
              />
            </GroupItem>
          </Group>
          <Group>
            <GroupItem lg={12} md={12} sm={12}>
              <MediaInput
                title={"filename"}
                label={fieldLabel("private_agreement_media")}
                source={fieldSource("private_agreement_media")}
                helperText={fieldLabel("private_agreement_media.help")}
                accept=".pdf,.p7m"
                isRequired
                options={{
                  onDropRejected: handleRejection,
                }}
              />
            </GroupItem>
          </Group>
          <Group>
            <GroupItem lg={12} md={12} sm={12}>
              <MediaInput
                title={"filename"}
                label={fieldLabel("private_contract_media")}
                source={fieldSource("private_contract_media")}
                helperText={fieldLabel("private_contract_media.help")}
                isRequired
                accept=".pdf,.p7m"
                options={{
                  onDropRejected: handleRejection,
                }}
              />
            </GroupItem>
          </Group>

          <Group>
            <GroupItem>
              <DebouncedTextInput
                source={fieldSource("private_website")}
                label={fieldLabel("private_website")}
                maxLength={100}
              />
            </GroupItem>
            <GroupItem>
              <DebouncedTextInput
                source={fieldSource("private_facebook")}
                label={fieldLabel("private_facebook")}
                maxLength={100}
              />
            </GroupItem>
            <GroupItem>
              <DebouncedTextInput
                source={fieldSource("private_instagram")}
                label={fieldLabel("private_instagram")}
                maxLength={100}
              />
            </GroupItem>
          </Group>
        </Group>
      )}
    </Group>
  );
};
export default PublicSpaceGroup1;
