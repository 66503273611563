import "./theme.css";

import * as Components from "./component";
import * as Icons from "@material-ui/icons";

import { API_URL, ENVIRONMENT } from "./config";
import {
  CrudProvider,
  CrudResource,
  WorkflowProvider,
  createI18nProvider,
  useAuthProvider,
  useDataProvider,
  useI18nCatcher,
  useI18nLanguages,
} from "ra-friendsofbabba";
import { LayoutDataContext, LayoutDataProvider } from "./component/LayoutData";
import {
  Loading,
  Admin as RaAdmin,
  Resource,
  RouteWithoutLayout,
} from "react-admin";
import React, { useContext } from "react";

import Layout from "./component/Layout";
import LoginPage from "./component/page/LoginPage";
import PageShow from "./component/show/PageShow";
import ProfilePage from "./component/page/ProfilePage";
import ResetPasswordPage from "./component/page/ResetPasswordPage";
import { Route } from "react-router-dom";
import SignupPage from "./component/page/SignupPage";
import executiveProject from "./resource/executive-project";
import executiveProjectCronPhaseAction from "./resource/executive-project-cron-phase-action";
import executiveProjectExpenseItem from "./resource/executive-project-expense-item";
import executiveProjectHumanResource from "./resource/executive-project-human-resource";
import executiveProjectLocalPartner from "./resource/executive-project-local-partner";
import executiveProjectPublicSpace from "./resource/executive-project-public-space";
import humanResource from "./resource/human-resource";
import localPartner from "./resource/local-partner";
import project from "./resource/project";
import publicSpace from "./resource/public-space";
import publicSpacesMedia from "./resource/public-spaces-media";
import theme from "./theme";

// Naming convention to readable code
const No = null;
const Admin = ({ authProvider, dataProvider, languages, ...props }) => {
  const { postTypes, loading } = useContext(LayoutDataContext);

  if (loading) {
    return (
      <Loading
        loadingPrimary="Loading data schema"
        loadingSecondary="Please wait..."
      />
    );
  }
  return (
    <RaAdmin
      {...props}
      initialState={{}}
      theme={theme}
      layout={Layout}
      loginPage={LoginPage}
      customRoutes={[
        <RouteWithoutLayout
          key="signup"
          path="/signup"
          component={SignupPage}
        />,
        <RouteWithoutLayout
          key="reset-password"
          path="/reset-password"
          component={ResetPasswordPage}
        />,
        <Route key="profile" path="/profile" component={ProfilePage} />,
      ]}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={createI18nProvider({
        languages: languages.data,
        locale: "it",
      })}
    >
      <CrudResource
        name="notifications"
        group="dashboard"
        icon={Icons.NotificationImportant}
      />
      <Resource name="projects" {...project} />
      <Resource name="public-spaces" {...publicSpace} />
      <Resource name="public-spaces-media" {...publicSpacesMedia} />
      <Resource name="local-partners" {...localPartner} />
      <Resource name="human-resources" {...humanResource} />
      <Resource name="municipalities/provinces" />
      <Resource name="public-space-types" />
      <Resource name="public-space-media-types" />

      <CrudResource name="post-taxonomies" group="admin" roles={["admin"]} />
      <CrudResource name="posts" group="admin" roles={["admin"]} />
      <CrudResource name="post-pictures" list={No} />
      <CrudResource name="post-attachments" list={No} />
      <CrudResource name="post-feedbacks" list={No} />
      <CrudResource name="post-links" list={No} />
      <CrudResource name="post-types" group="admin" roles={["admin"]} />
      <CrudResource name="post-type-fields" group="admin" list={No} />
      <CrudResource name="post-sources" group="admin" roles={["admin"]} />
      {postTypes.map((postType) => (
        <CrudResource
          group="posts"
          key={postType.id}
          name={postType.resource}
          options={{
            label: postType.name,
          }}
          roles={postType.roles?.map((r) => r.code)}
        />
      ))}
      <Resource name="related-posts" />
      <Resource name="post-tags" />
      <Resource name="post-type-fields/source-tables" />
      <Resource name="post-type-fields/implemented" />
      <Resource name="post-field-data-sources" />
      <Resource name="post-field-data-sources/names" />
      <Resource name="executive-projects" {...executiveProject} />
      <Resource
        name="executive-project-local-partners"
        {...executiveProjectLocalPartner}
      />
      <Resource
        name="executive-project-human-resources"
        {...executiveProjectHumanResource}
      />
      <Resource
        name="executive-project-public-spaces"
        {...executiveProjectPublicSpace}
      />
      <Resource
        name="executive-project-cron-phase-actions"
        {...executiveProjectCronPhaseAction}
      />
      <Resource
        name="executive-project-expense-items"
        {...executiveProjectExpenseItem}
      />
      <Resource name="municipalities" group="admin" />
      <Resource name="owner-types" />
      <Resource name="expense-types" />
      <Resource name="budget-management-types" />
      <Resource name="countries" />
      <Resource name="workflow/transactions/posts" />
      <Resource name="workflow/transactions/projects" />
      <Resource name="workflow/transactions/executive-projects" />
      <Resource name="roles" />
      <Resource name="languages" />
      <CrudResource
        name="project-time-windows"
        group="admin"
        roles={["admin"]}
        components={Components}
      />
      <CrudResource name="command-log-rows" group="admin" roles={["admin"]} />
      <CrudResource
        name="pages"
        group="admin"
        roles={["admin"]}
        icon={Icons.Pages}
        show={PageShow}
      />
      <CrudResource
        name="language-messages"
        group="admin"
        roles={ENVIRONMENT !== "production" ? ["admin"] : ["?"]}
        icon={Icons.FlagOutlined}
      />
      <CrudResource
        name="users"
        group="admin"
        roles={["admin", "youth-worker", "oc"]}
        icon={Icons.AccountCircle}
      />
    </RaAdmin>
  );
};
const App = () => {
  const apiUrl = API_URL;
  const languages = useI18nLanguages({ apiUrl });

  // Allow i18n to intercept and send unlocalized messages to the server.
  useI18nCatcher({ apiUrl, loading: languages?.loading });

  const dataProvider = useDataProvider({
    apiUrl,
    fileFields: [
      // Nodes
      "civil_operator_curriculum_media",
      "curriculum_media",
      "expression_of_interest_media",
      "media_collection",
      "media",
      "private_agreement_media",
      "private_contract_media",
      "signed_doc_media",
      "support_and_commitments_letter_media",

      // Website
      "featured_image",
      "picture",
      "attachment",
    ],
  });
  const authProvider = useAuthProvider({ apiUrl });
  if (languages?.loading) {
    return (
      <Loading
        loadingPrimary="Loading i18n strings"
        loadingSecondary="Please wait..."
      />
    );
  }

  return (
    <WorkflowProvider apiUrl={apiUrl}>
      <CrudProvider apiUrl={apiUrl} components={Components}>
        <LayoutDataProvider apiUrl={apiUrl}>
          <Admin
            authProvider={authProvider}
            dataProvider={dataProvider}
            languages={languages}
          />
        </LayoutDataProvider>
      </CrudProvider>
    </WorkflowProvider>
  );
};
export default App;
