import {
  Group,
  GroupItem,
  GroupTitle,
  ReferenceListField,
  useWorkflowInput,
} from "ra-friendsofbabba";
import React, { Fragment, useMemo } from "react";

import { TextField } from "ra-ui-materialui";
import useFormPermission from "../input/useFormPermission";

const ExecutiveProjectHumanResourcesField = ({
  visible,
  fieldLabel,
  ...props
}) => {
  const { disabled } = useWorkflowInput(props);
  const hasFormPermission = useFormPermission();
  const hasPermission = useMemo(
    () => hasFormPermission(props?.source),
    [hasFormPermission, props?.source]
  );

  return (
    <Fragment>
      <GroupTitle title={fieldLabel(props?.source)} />
      <Group {...props}>
        <GroupItem lg={12} md={12} sm={12} xs={12}>
          <ReferenceListField
            addLabel={false}
            createLabel="ra.action.add"
            reference="executive-project-human-resources"
            target="executive_project_id"
            tab={1}
            mobileBreakpoint="sm"
            mobilePrimaryText="description"
            mobileSecondaryText="role"
            create={!disabled && hasPermission}
            modify={!disabled && hasPermission}
            remove={!disabled && hasPermission}
          >
            <TextField source="name" />
            <TextField source="surname" />
          </ReferenceListField>
        </GroupItem>
      </Group>
    </Fragment>
  );
};

export default ExecutiveProjectHumanResourcesField;
