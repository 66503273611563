import { DebouncedTextInput } from "ra-friendsofbabba";
import React from "react";
import { required } from "react-admin";
import { url } from "../../../../utility/validation";

const Url = ({ field, values, translate, ...props }) => (
  <DebouncedTextInput
    {...props}
    source={field.code}
    label={field.name}
    type="url"
    validate={[field?.is_required && required(), url()]}
  />
);

export default Url;
