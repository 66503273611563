import {
  Group,
  GroupItem,
  GroupTitle,
  LongTextField,
  ReferenceListField,
  useFieldLabel,
  useWorkflowInput,
} from "ra-friendsofbabba";
import React, { Fragment, useMemo } from "react";

import useFormPermission from "../input/useFormPermission";

const HumanResourcesField = ({ visible, ...props }) => {
  const fieldLabel = useFieldLabel({ resource: "human-resources" });
  const { disabled } = useWorkflowInput({
    ...props,
  });
  const hasFormPermission = useFormPermission();
  const hasHumanResourcesPermission = useMemo(
    () => hasFormPermission("human_resources"),
    [hasFormPermission]
  );
  if (!visible) {
    return null;
  }
  return (
    <Fragment>
      <GroupTitle
        title={fieldLabel("human_resources")}
        subTitle={fieldLabel("human_resources.help")}
      />
      <Group {...props}>
        <GroupItem lg={12} md={12} sm={12} xs={12}>
          <ReferenceListField
            addLabel={false}
            createLabel="ra.action.add"
            reference="human-resources"
            target="project_id"
            tab={3}
            mobileBreakpoint="sm"
            mobilePrimaryText="description"
            mobileSecondaryText="role"
            create={!disabled && hasHumanResourcesPermission}
            modify={!disabled && hasHumanResourcesPermission}
            remove={!disabled && hasHumanResourcesPermission}
          >
            <LongTextField source="description" />
            <LongTextField source="role" />
          </ReferenceListField>
        </GroupItem>
      </Group>
    </Fragment>
  );
};

export default HumanResourcesField;
