import { Collapse, Typography } from "@material-ui/core";
import { Group, GroupItem, GroupTitle, useFieldLabel } from "ra-friendsofbabba";

import ExecutiveProjectLocalPartnersPercInput from "./ExecutiveProjectLocalPartnersPercInput";
import PercentageInput from "./PercentageInput";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { useFormState } from "react-final-form";
import { useMemo } from "react";

const sumPercs = (items, publicPerc, privatePerc) => {
  const percs =
    items?.map((lp) => parseFloat(lp.budget_management_perc) || 0) || [];
  const publicPercFloat = parseFloat(publicPerc) || 0;
  const privatePercFloat = parseFloat(privatePerc) || 0;

  const total = parseInt(
    percs.reduce((sum, perc) => sum + perc, 0) +
      publicPercFloat +
      privatePercFloat,
    10
  );
  return total;
};
const validate =
  (message = "ra.validation.perc") =>
  (_, values) => {
    const state = values?.transaction?.state;
    const total = sumPercs(
      values?.executive_project_local_partners,
      values?.budget_management_public_perc,
      values?.budget_management_private_perc
    );
    if (total > 100 && state === "draft") {
      return message;
    }
    if (total !== 100 && state !== "draft") {
      return message;
    }
  };
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    "& .MuiTypography-caption": {
      paddingLeft: theme.spacing(0.5),
    },
    "& .MuiTypography-body1": {
      paddingLeft: theme.spacing(0.5),
    },
  },
}));
const TotalPerc = ({ fieldLabel, value }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="caption" color="textSecondary">
        {fieldLabel("budget_management_total_perc")}
      </Typography>
      <Typography variant="body1">{value} %</Typography>
    </div>
  );
};
const BudgetManagementPercsInput = (props) => {
  const { values } = useFormState({ subscription: { values: true } });
  const fieldLabel = useFieldLabel(props);

  const visible = useMemo(
    () => values?.budget_management_type_id === 3,
    [values?.budget_management_type_id]
  );
  const total = useMemo(
    () =>
      sumPercs(
        values?.executive_project_local_partners,
        values?.budget_management_private_perc,
        values?.budget_management_public_perc
      ),
    [
      values?.executive_project_local_partners,
      values?.budget_management_public_perc,
      values?.budget_management_private_perc,
    ]
  );
  return (
    <Collapse in={visible} unmountOnExit>
      <Group {...props} fullWidth wrapper>
        <GroupTitle title={fieldLabel(props?.source)} />
        <Group>
          <GroupItem>
            <PercentageInput
              validate={validate()}
              source="budget_management_public_perc"
            />
          </GroupItem>
          <GroupItem>
            <PercentageInput
              validate={validate()}
              source="budget_management_private_perc"
            />
          </GroupItem>
        </Group>
        <Group>
          <GroupItem lg={12} md={12} sm={12} xs={12}>
            <ExecutiveProjectLocalPartnersPercInput
              validate={validate()}
              source="executive_project_local_partners"
            />
          </GroupItem>
        </Group>

        <Group>
          <GroupItem lg={4} />
          <GroupItem>
            <TotalPerc value={total} fieldLabel={fieldLabel} />
          </GroupItem>
        </Group>
      </Group>
    </Collapse>
  );
};

export default BudgetManagementPercsInput;
