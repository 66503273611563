import {
  DebouncedTextInput,
  Group,
  GroupItem,
  GroupTitle,
} from "ra-friendsofbabba";
import { Typography, makeStyles } from "@material-ui/core";

import ExecutiveProjectExpenseItemListField from "./ExecutiveProjectExpenseItemListField";
import MoneyField from "./MoneyField";
import { MoneyInput } from "../input";
import React from "react";
import { useQueryWithStore } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    "& .MuiTypography-caption": {
      paddingLeft: theme.spacing(0.5),
    },
    "& .MuiTypography-body1": {
      paddingLeft: theme.spacing(0.5),
    },
  },
}));
const TotalField = ({ source, label, record }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>
      <MoneyField source={source} record={record} />
    </div>
  );
};

const ExecutiveProjectExpenseItemsField = ({
  fieldLabel,
  disabled,
  ...props
}) => {
  const { data: expenseTypes } = useQueryWithStore({
    type: "getList",
    resource: "expense-types",
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "id", order: "ASC" },
      filter: {},
    },
  });
  const { data } = useQueryWithStore({
    type: "get",
    resource: "executive-project-expense-items/totals",
    payload: {
      executive_project_id: props.record?.id,
    },
  });
  return (
    <Group {...props} fullWidth wrapper>
      <GroupTitle title={fieldLabel("executive_project_expense_items")} />
      <Group>
        <GroupItem lg={4} md={6} sm={12}>
          <MoneyInput
            source="budget_management_amount"
            variant="outlined"
            disabled
          />
        </GroupItem>
      </Group>
      {expenseTypes?.map((expenseType) => (
        <ExecutiveProjectExpenseItemListField
          {...props}
          key={expenseType.id}
          fullWidth
          disabled={disabled}
          source="executive_project_expense_items"
          fieldLabel={fieldLabel}
          expenseType={expenseType}
        />
      ))}
      <Group wrapper>
        <GroupTitle
          title={fieldLabel("executive_project_expense_items.totals")}
        />

        <Group>
          <GroupItem>
            <TotalField
              source="totals.phase1"
              record={data}
              label={fieldLabel(
                "executive_project_expense_items.totals.phase1"
              )}
            />
          </GroupItem>
          <GroupItem>
            <TotalField
              source="totals.phase2"
              record={data}
              label={fieldLabel(
                "executive_project_expense_items.totals.phase2"
              )}
            />
          </GroupItem>
          <GroupItem>
            <TotalField
              source="totals.total"
              record={data}
              label={fieldLabel("executive_project_expense_items.totals.total")}
            />
          </GroupItem>
        </Group>
        <Group>
          <GroupItem lg={12} md={12} sm={12}>
            <DebouncedTextInput
              source="expense_items_notes"
              multiline
              maxLength={1000}
              disabled={disabled}
            />
          </GroupItem>
        </Group>
      </Group>
    </Group>
  );
};
export default ExecutiveProjectExpenseItemsField;
