import {
  DebouncedTextInput,
  FormTab,
  Group,
  GroupItem,
  GroupTitle,
  ValidationSummary,
  useTabVisibility,
  useWorkflowInput,
} from "ra-friendsofbabba";
import React, { useMemo } from "react";

import DirtyFormAlert from "../../alert/DirtyFormAlert";
import useFormPermission from "../../input/useFormPermission";

const FIELDS = [
  "civil_service_goals",
  "civil_service_activities",
  "civil_service_opportunities",
];
const SOURCE = "civil_service";
const ExecutiveProjectTab5 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(5);
  const { disabled } = useWorkflowInput({ ...props, source: SOURCE });
  const hasFormPermission = useFormPermission();
  const hasPermission = useMemo(
    () => hasFormPermission(SOURCE),
    [hasFormPermission]
  );
  return (
    <FormTab {...props} label={fieldLabel("tabs.section_5")}>
      {visible && <ValidationSummary />}
      {visible && <DirtyFormAlert />}
      {FIELDS.map((field) => (
        <Group key={field} fullWidth wrapper>
          <GroupTitle
            title={fieldLabel(field)}
            subTitle={fieldLabel(`${field}.help`)}
          />
          <Group>
            <GroupItem lg={10} md={10} sm={10}>
              <DebouncedTextInput
                multiline
                minRows={3}
                source={field}
                maxLength={1000}
                disabled={disabled || !hasPermission}
              />
            </GroupItem>
          </Group>
        </Group>
      ))}
    </FormTab>
  );
};
export default ExecutiveProjectTab5;
