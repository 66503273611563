import {
  AutocompleteInput,
  ReferenceAutocompleteInput,
} from "ra-friendsofbabba";

import NotImplemented from "./NotImplemented";
import { required } from "react-admin";

const Autocomplete = ({ field, values, translate, fullWidth, ...props }) => {
  const mode = field?.config?.mode;
  if (mode === "Autofill") {
    return (
      <ReferenceAutocompleteInput
        {...props}
        source={field.code}
        label={field.name}
        reference="post-field-data-sources"
        optionText="item"
        optionValue="id"
        filter={{ name: field?.config?.autofill }}
        validate={field?.is_required && required()}
      />
    );
  } else if (mode === "Table") {
    return (
      <ReferenceAutocompleteInput
        {...props}
        source={field.code}
        label={field.name}
        reference={field?.config?.reference}
        optionText={field?.config?.optionText}
        optionValue={field?.config?.optionValue}
        validate={field?.is_required && required()}
      />
    );
  } else if (mode === "List") {
    return (
      <AutocompleteInput
        {...props}
        source={field.code}
        label={field.name}
        choices={field?.config?.choices}
        optionText="name"
        optionValue="name"
        validate={field?.is_required && required()}
      />
    );
  }
  return <NotImplemented field={field} translate={translate} />;
};

export default Autocomplete;
