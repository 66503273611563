import {
  FormTab,
  TransactionLogsField,
  TransactionNotesInput,
  TransactionNotesIsPrivateInput,
  useTabVisibility,
} from "ra-friendsofbabba";

import DirtyFormAlert from "../../alert/DirtyFormAlert";
import { LockOpen } from "@material-ui/icons";
import { PermissionsInput } from "../../input";
import React from "react";

const ExecutiveProjectTab7 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(7);

  return (
    <FormTab
      {...props}
      label={fieldLabel("tabs.section_7")}
      icon={<LockOpen />}
    >
      {visible && <DirtyFormAlert />}
      {visible && <PermissionsInput source="permissions" fullWidth />}

      {visible && <TransactionNotesInput source="notes" fullWidth />}
      {visible && <TransactionNotesIsPrivateInput source="is_private" />}
      {visible && <TransactionLogsField source="logs" fullWidth />}
    </FormTab>
  );
};
export default ExecutiveProjectTab7;
