import { BooleanInput, required } from "react-admin";

import React from "react";

const Boolean = ({ field, values, translate, fullWidth, ...props }) => (
  <BooleanInput
    {...props}
    source={field.code}
    label={field.name}
    validate={[field?.is_required && required()]}
  />
);

export default Boolean;
