import { BooleanInput, SelectInput } from "react-admin";
import { Group, GroupItem } from "ra-friendsofbabba";

import { useMemo } from "react";

const Sizes = [...Array(12).keys()].map((id) => ({
  id: id + 1,
  name: id + 1,
}));

const Disposition = (props) => (
  <Group wrapper {...props}>
    <Group>
      {["lg", "md", "sm", "xs"].map((size) => (
        <GroupItem key={size} lg={3} md={3} sm={6} xs={12}>
          <SelectInput
            label={size}
            source={`config.disposition.${size}`}
            choices={Sizes}
          />
        </GroupItem>
      ))}
    </Group>
    <Group>
      <GroupItem lg={4} md={4} sm={6} xs={12}>
        <BooleanInput source="config.disposition.breakRow" label="Break Row" />
      </GroupItem>
    </Group>
  </Group>
);

export const useDisposition = (field, defaultSize) =>
  useMemo(
    () => ({
      disposition: {
        lg: field?.config?.disposition?.lg || defaultSize,
        md: field?.config?.disposition?.md || defaultSize,
        sm: field?.config?.disposition?.sm || defaultSize,
        xs: field?.config?.disposition?.xs || defaultSize,
      },
      breakRow: field?.config?.disposition?.breakRow || false,
    }),
    [field, defaultSize]
  );

export default Disposition;
