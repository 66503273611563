import {
  DebouncedTextInput,
  Group,
  GroupItem,
  GroupTitle,
  MediaInput,
  useFieldLabel,
  useWorkflowInput,
} from "ra-friendsofbabba";
import React, { useEffect, useMemo } from "react";
import { email, required, useNotify } from "ra-core";
import { useForm, useFormState } from "react-final-form";

import { BooleanInput } from "react-admin";
import MunicipalityInput from "./MunicipalityInput";
import ProvinceInput from "./ProvinceInput";
import PublicAuthorityPartnersInput from "./PublicAuthorityPartnersInput";
import { get } from "lodash";
import { useCallback } from "react";
import useFormPermission from "./useFormPermission";

const HeadingGroups = ({ fieldLabel, fieldSource, ...props }) => {
  const { visible, disabled } = useWorkflowInput({
    ...props,
    source: "public_authority.heading",
  });
  const hasFormPermission = useFormPermission();
  const hasHeadingPermission = useMemo(
    () => hasFormPermission("public_authority.heading"),
    [hasFormPermission]
  );
  if (!visible) {
    return null;
  }
  return (
    <Group wrapper {...props}>
      <GroupTitle title={fieldLabel("heading")} />
      <Group>
        <GroupItem lg={3} md={3} xs={12}>
          <ProvinceInput
            source={fieldSource("province")}
            label={fieldLabel("province")}
            validate={required()}
            disabled={disabled || !hasHeadingPermission}
          />
        </GroupItem>
        <GroupItem>
          <MunicipalityInput
            source={fieldSource("municipality_id")}
            label={fieldLabel("municipality_id")}
            deps={{ province: fieldSource("province") }}
            validate={required()}
            disabled={disabled || !hasHeadingPermission}
          />
        </GroupItem>
        <GroupItem>
          <DebouncedTextInput
            source={fieldSource("code")}
            label={fieldLabel("code")}
            maxLength={20}
            disabled={disabled || !hasHeadingPermission}
            isRequired
          />
        </GroupItem>
      </Group>
    </Group>
  );
};
const RepGroup = ({ fieldLabel, fieldSource, ...props }) => {
  const { visible, disabled } = useWorkflowInput({
    ...props,
    source: "public_authority.rep",
  });
  const hasFormPermission = useFormPermission();
  const hasRepPermission = useMemo(
    () => hasFormPermission("public_authority.rep"),
    [hasFormPermission]
  );
  if (!visible) {
    return null;
  }
  return (
    <Group wrapper {...props}>
      <GroupTitle title={fieldLabel("rep")} subTitle={fieldLabel("rep.help")} />
      <Group>
        <GroupItem>
          <DebouncedTextInput
            source={fieldSource("rep_name")}
            label={fieldLabel("rep_name")}
            helperText={fieldLabel("rep_name.helper")}
            maxLength={50}
            disabled={disabled || !hasRepPermission}
            isRequired
          />
        </GroupItem>
        <GroupItem>
          <DebouncedTextInput
            source={fieldSource("rep_surname")}
            label={fieldLabel("rep_surname")}
            helperText={fieldLabel("rep_surname.helper")}
            maxLength={50}
            disabled={disabled || !hasRepPermission}
            isRequired
          />
        </GroupItem>
      </Group>
    </Group>
  );
};
const AccountableGroup = ({ fieldLabel, fieldSource, ...props }) => {
  const { visible, disabled } = useWorkflowInput({
    ...props,
    source: "public_authority.accountable",
  });
  const hasFormPermission = useFormPermission();
  const hasAccountablePermission = useMemo(
    () => hasFormPermission("public_authority.accountable"),
    [hasFormPermission]
  );
  const notify = useNotify();
  const handleRejection = useCallback(
    () => notify("ra.message.file_rejected", { type: "error" }),
    [notify]
  );
  if (!visible) {
    return null;
  }
  return (
    <Group wrapper {...props}>
      <GroupTitle
        title={fieldLabel("accountable")}
        subTitle={fieldLabel("accountable.help")}
      />
      <Group>
        <GroupItem>
          <DebouncedTextInput
            source={fieldSource("accountable_name")}
            label={fieldLabel("accountable_name")}
            maxLength={50}
            disabled={disabled || !hasAccountablePermission}
            isRequired
          />
        </GroupItem>
        <GroupItem>
          <DebouncedTextInput
            source={fieldSource("accountable_surname")}
            label={fieldLabel("accountable_surname")}
            maxLength={50}
            disabled={disabled || !hasAccountablePermission}
            isRequired
          />
        </GroupItem>
        <GroupItem>
          <DebouncedTextInput
            source={fieldSource("accountable_fiscal_code")}
            label={fieldLabel("accountable_fiscal_code")}
            maxLength={16}
            disabled={disabled || !hasAccountablePermission}
            isRequired
          />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem>
          <DebouncedTextInput
            source={fieldSource("accountable_role")}
            label={fieldLabel("accountable_role")}
            maxLength={50}
            disabled={disabled || !hasAccountablePermission}
            isRequired
          />
        </GroupItem>
        <GroupItem>
          <DebouncedTextInput
            source={fieldSource("accountable_email")}
            label={fieldLabel("accountable_email")}
            maxLength={100}
            validate={email()}
            disabled={disabled || !hasAccountablePermission}
            isRequired
          />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem>
          <DebouncedTextInput
            source={fieldSource("accountable_phone")}
            label={fieldLabel("accountable_phone")}
            maxLength={30}
            disabled={disabled || !hasAccountablePermission}
            isRequired
          />
        </GroupItem>
        <GroupItem>
          <DebouncedTextInput
            source={fieldSource("accountable_mobile")}
            label={fieldLabel("accountable_mobile")}
            maxLength={30}
            disabled={disabled || !hasAccountablePermission}
            isRequired
          />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem lg={8} md={12}>
          <DebouncedTextInput
            source={fieldSource("email")}
            label={fieldLabel("email")}
            maxLength={100}
            validate={email()}
            disabled={disabled || !hasAccountablePermission}
            isRequired
          />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem lg={12} md={12}>
          <MediaInput
            title="filename"
            accept=".pdf,.p7m"
            source={fieldSource("expression_of_interest_media")}
            label={fieldLabel("expression_of_interest_media")}
            helperText={fieldLabel("expression_of_interest_media.helper")}
            disabled={disabled || !hasAccountablePermission}
            isRequired
            options={{
              onDropRejected: handleRejection,
            }}
          />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem lg={12} md={12} sm={12}>
          <BooleanInput
            source={fieldSource("is_group")}
            label={fieldLabel("is_group")}
            disabled={disabled || !hasAccountablePermission}
          />
        </GroupItem>
      </Group>
      <PublicAuthorityPartnersInput
        source={fieldSource("public_authority_partners")}
        dep={fieldSource("is_group")}
        disabled={disabled || !hasAccountablePermission}
        fullWidth
        isRequired
      />
    </Group>
  );
};
const PublicAuthorityInput = ({ visible, ...props }) => {
  const fieldLabel = useFieldLabel({ resource: "public-authorities" });
  const fieldSource = useCallback(
    (source) => `${props.source}.${source}`,
    [props.source]
  );
  // Se aggiorno Provincia e Comune devo propagare automaticamente le
  // informazioni anche sullo spazio pubblico individuato.
  const form = useForm();
  const { values } = useFormState({ subscription: { values: true } });
  const { province, municipality_id } = useMemo(
    () => ({
      province: get(values, fieldSource("province")),
      municipality_id: get(values, fieldSource("municipality_id")),
    }),
    [values, fieldSource]
  );
  useEffect(() => {
    form.batch(() => {
      form.change("public_space.province", province);
      form.change("public_space.municipality_id", municipality_id);
    });
  }, [province, municipality_id, form]);
  return (
    <Group wrapper {...props} {...{ fieldLabel, fieldSource }}>
      <HeadingGroups />
      <RepGroup />
      <AccountableGroup />
    </Group>
  );
};

export default PublicAuthorityInput;
