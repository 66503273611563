import {
  Alert,
  AlertTitle,
  MediaInput,
  Toolbar,
  useMakeRedirect,
  useSaveMutation,
} from "ra-friendsofbabba";
import { Collapse, Typography } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import {
  SimpleForm,
  required,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";

import { MAX_UPLOAD_FILE_SIZE } from "../../config";
import PublicSpaceMediaTypeInput from "../input/PublicSpaceMediaTypeInput";
import { useFormState } from "react-final-form";

const useMultiple = () => {
  const { values } = useFormState({ subscription: { values: true } });
  const { multiple, pending } = useMemo(
    () => ({
      multiple: values?.public_space_media_type_id > 1,
      pending: values?.public_space_media_type_id === undefined,
    }),
    [values]
  );
  return { multiple, pending };
};
const SmartMediaInput = (props) => {
  const { multiple } = useMultiple();
  const notify = useNotify();
  const accept = useMemo(
    () => (multiple ? "image/*" : ".pdf,.p7m"),
    [multiple]
  );
  const handleRejection = useCallback(
    () =>
      multiple
        ? notify("ra.message.files_rejected", { type: "error" })
        : notify("ra.message.file_rejected", { type: "error" }),
    [multiple, notify]
  );
  return (
    <MediaInput
      {...props}
      validate={required()}
      multiple={multiple}
      accept={accept}
      maxSize={MAX_UPLOAD_FILE_SIZE}
      options={{
        onDropRejected: handleRejection,
      }}
    />
  );
};
const SmartAlert = () => {
  const { multiple, pending } = useMultiple();
  const translate = useTranslate();
  return (
    <Collapse in={!pending}>
      <Alert severity="info">
        <AlertTitle>
          {translate("resources.public-spaces-media.alert.title")}
        </AlertTitle>
        <Typography variant="body1">
          {translate(
            `resources.public-spaces-media.alert.${
              pending ? "pending" : multiple ? "multiple" : "single"
            }`
          )}
        </Typography>
      </Alert>
    </Collapse>
  );
};
const PublicSpacesMediaForm = (props) => {
  const redirect = useRedirect();
  const backRedirect = useMakeRedirect({
    resource: "projects",
    record: {
      id: props?.record?.project_id,
    },
    tab: 1,
  });
  const handleSuccess = useCallback(
    () => redirect(backRedirect),
    [redirect, backRedirect]
  );
  const save = useSaveMutation({
    ...props,
    onSuccess: handleSuccess,
  });

  return (
    <SimpleForm
      {...props}
      toolbar={
        <Toolbar
          backTab={1}
          backReferenceTarget="project_id"
          backReference="projects"
        />
      }
      save={save}
    >
      <PublicSpaceMediaTypeInput
        source="public_space_media_type_id"
        validate={required()}
      />
      <SmartAlert />
      <SmartMediaInput source="media" validate={required()} title="filename" />
    </SimpleForm>
  );
};
export default PublicSpacesMediaForm;
