import { maxValue, minValue } from "react-admin";

import { DebouncedNumberInput } from "ra-friendsofbabba";
import React from "react";

// const defaultChoices = Array.from(Array(56).keys())
//   .filter((id) => id >= 7)
//   .map((id) => ({ id: id + 1, name: id + 1 }));

const WorkingHoursInput = (props) => (
  <DebouncedNumberInput {...props} validate={[minValue(8), maxValue(56)]} />
);
export default WorkingHoursInput;
