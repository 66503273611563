export const url =
  (message = "ra.validation.invalid_url") =>
  (value) => {
    if (!value) return;
    try {
      const url = new URL(value);
      if (!url.protocol.startsWith("http")) return message;
      if (!url.hostname) return message;
    } catch (e) {
      return message;
    }

    return;
  };
