import React, { useMemo } from "react";
import { TabbedForm, useNotify, usePermissions, useRefresh } from "react-admin";
import { Toolbar, useFieldLabel, useSaveMutation } from "ra-friendsofbabba";

import { DRAWER_WIDTH } from "../../config";
import ExecutiveProjectTab0 from "./tab/ExecutiveProjectTab0";
import ExecutiveProjectTab1 from "./tab/ExecutiveProjectTab1";
import ExecutiveProjectTab2 from "./tab/ExecutiveProjectTab2";
import ExecutiveProjectTab3 from "./tab/ExecutiveProjectTab3";
import ExecutiveProjectTab4 from "./tab/ExecutiveProjectTab4";
import ExecutiveProjectTab5 from "./tab/ExecutiveProjectTab5";
import ExecutiveProjectTab6 from "./tab/ExecutiveProjectTab6";
import ExecutiveProjectTab7 from "./tab/ExecutiveProjectTab7";
import { PrintButton } from "../button";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: ({ sidebarOpen, sidebarWidth }) => ({
    "& .MuiTabs-scroller.MuiTabs-fixed": {
      overflowX: "auto !important",
      [theme.breakpoints.up("md")]: {
        maxWidth: sidebarOpen
          ? `calc(100vw - ${sidebarWidth + 64}px)`
          : "calc(100vw - 65px)",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: sidebarOpen
          ? `calc(100vw - ${sidebarWidth + 64}px)`
          : "calc(100vw - 65px)",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "calc(100vw - 17px)",
      },
    },
  }),
  help: {
    marginBottom: theme.spacing(2),
  },
}));
export const useCanSubmit = ({ tab = false, record }) =>
  useMemo(() => {
    if (!record || !record?.id) {
      return false;
    }
    const validationErrors = record?.validationErrors || {};
    const errors = Object.keys(validationErrors);
    const count = errors.length;
    const valid = count === 0 || errors[0] === "signed_doc_media";
    const state = record?.transaction?.state;
    return tab
      ? valid && ["approved", "uploaded"].indexOf(state) !== -1
      : valid;
  }, [record, tab]);

const ExecutiveProjectForm = ({ create = false, ...props }) => {
  const fieldLabel = useFieldLabel(props);
  const refresh = useRefresh();
  const notify = useNotify();
  const canSubmit = useCanSubmit({ tab: true, ...props });
  const isCivilServiceReady = useMemo(
    () => props?.record?.is_civil_service_ready,
    [props]
  );
  const save = useSaveMutation({
    ...props,
    onSuccess:
      props.record.id > 0
        ? (values, response) => {
            const prevState = values?.data?.transaction?.state;
            const nextState = values?.data?.state;
            const loop = nextState === prevState;
            refresh();
            notify(
              `resources.executive-projects.notify.${nextState}_${
                loop ? "loop" : "success"
              }`
            );
          }
        : undefined,
  });
  const sidebarOpen = useSelector((state) => state?.admin?.ui?.sidebarOpen);
  const sidebarWidth = DRAWER_WIDTH;
  const classes = useStyles({ sidebarOpen, sidebarWidth });
  const { loaded, permissions } = usePermissions();
  const isAdmin = useMemo(
    () => loaded && permissions && permissions(["admin", "region"]),
    [loaded, permissions]
  );

  return (
    <TabbedForm
      {...props}
      className={classes.root}
      warnWhenUnsavedChanges
      toolbar={
        <Toolbar useWorkflow maxButtonsToDisplay={2}>
          <PrintButton label="resources.executive-projects.buttons.pdf_preview" />
        </Toolbar>
      }
      save={save}
    >
      <ExecutiveProjectTab0 fieldLabel={fieldLabel} />
      <ExecutiveProjectTab1 fieldLabel={fieldLabel} />
      <ExecutiveProjectTab2 fieldLabel={fieldLabel} />
      <ExecutiveProjectTab3 fieldLabel={fieldLabel} />
      <ExecutiveProjectTab4 fieldLabel={fieldLabel} />
      {isCivilServiceReady && <ExecutiveProjectTab5 fieldLabel={fieldLabel} />}
      {canSubmit && <ExecutiveProjectTab6 fieldLabel={fieldLabel} />}
      {isAdmin && <ExecutiveProjectTab7 fieldLabel={fieldLabel} />}
    </TabbedForm>
  );
};

export default ExecutiveProjectForm;
