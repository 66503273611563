import * as inputs from "./post/fields";

import { Fragment, useContext } from "react";
import { Group, GroupItem } from "ra-friendsofbabba";
import { LoadingIndicator, useTranslate } from "react-admin";

import { LayoutDataContext } from "../LayoutData";
import React from "react";
import { get } from "lodash";
import { useDisposition } from "./post/fields/config/Disposition";
import { useFormState } from "react-final-form";
import { useMemo } from "react";

const PostFieldInput = ({ field, ...props }) => {
  const Input = inputs[field.type] || inputs.NotImplemented;
  const { disposition, breakRow } = useDisposition(field, 12);

  return (
    <Fragment>
      {breakRow && <GroupItem lg={12} md={12} sm={12} xs={12} />}
      <GroupItem {...props} {...disposition}>
        <Input field={field} />
      </GroupItem>
    </Fragment>
  );
};

const PostFieldsInput = ({ ...props }) => {
  const translate = useTranslate();
  const { values } = useFormState({ subscription: { values: true } });
  const { postTypes } = useContext(LayoutDataContext);
  const postType = useMemo(
    () => postTypes?.find((pt) => pt.id === get(values, "post_type_id")),
    [postTypes, values]
  );
  if (postType === null) {
    return <LoadingIndicator />;
  }
  return (
    <Group fullWidth>
      {postType?.fields?.map((field) => (
        <PostFieldInput
          key={field.name}
          field={field}
          values={values}
          translate={translate}
          {...props}
        />
      )) || null}
    </Group>
  );
};

export default PostFieldsInput;
