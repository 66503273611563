import {
  DebouncedTextInput,
  Group,
  GroupItem,
  GroupTitle,
  useWorkflowInput,
} from "ra-friendsofbabba";
import React, { useMemo } from "react";

import { email } from "react-admin";
import useFormPermission from "./useFormPermission";

const ExecutiveProjectOperationalContactInput = ({ fieldLabel, ...props }) => {
  const { disabled } = useWorkflowInput(props);
  const hasFormPermission = useFormPermission();
  const hasPermission = useMemo(
    () => hasFormPermission(props?.source),
    [hasFormPermission, props?.source]
  );
  return (
    <Group {...props} disabled={disabled || !hasPermission} fullWidth wrapper>
      <GroupTitle title={fieldLabel(props?.source)} />
      <Group>
        <GroupItem lg={5}>
          <DebouncedTextInput
            source="operational_contact_name"
            maxLength={50}
          />
        </GroupItem>
        <GroupItem lg={5}>
          <DebouncedTextInput
            source="operational_contact_surname"
            maxLength={50}
          />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem lg={5}>
          <DebouncedTextInput
            source="operational_contact_phone"
            maxLength={50}
          />
        </GroupItem>
        <GroupItem lg={5}>
          <DebouncedTextInput
            source="operational_contact_email"
            maxLength={50}
            type="email"
            validate={email()}
          />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem lg={5}>
          <DebouncedTextInput
            source="operational_contact_company"
            maxLength={100}
          />
        </GroupItem>
      </Group>
    </Group>
  );
};
export default ExecutiveProjectOperationalContactInput;
