import {
  DeleteWithConfirmButton,
  Group,
  GroupItem,
  GroupTitle,
  LongTextField,
  ReferenceListField,
  useMakeRedirect,
} from "ra-friendsofbabba";

import { EditButton } from "ra-ui-materialui";
import MoneyField from "./MoneyField";
import PhaseField from "./PhaseField";

const SmartEditButton = (props) =>
  props?.record?.phase < 3 && <EditButton {...props} />;
const SmartDeleteButton = (props) =>
  props?.record?.phase < 3 && <DeleteWithConfirmButton {...props} />;
const ExecutiveProjectExpenseItemListField = ({
  visible,
  disabled,
  fieldLabel,
  expenseType,
  ...props
}) => {
  const removeRedirect = useMakeRedirect({
    ...props,
    tab: 4,
  });
  return (
    <Group wrapper>
      <GroupTitle title={expenseType?.name} />
      <Group {...props}>
        <GroupItem lg={12} md={12} sm={12}>
          <ReferenceListField
            tab={2}
            addLabel={false}
            filter={{ expense_type_id: expenseType?.id, sum: true }}
            additionalData={{ expense_type_id: expenseType?.id }}
            createLabel="ra.action.add"
            reference="executive-project-expense-items"
            target="executive_project_id"
            mobileBreakpoint="sm"
            mobilePrimaryText={"expense_item.name"}
            mobileSecondaryText="expense_value"
            create={!disabled}
            modify={false}
            remove={false}
          >
            <LongTextField source="description" width={200} />
            <MoneyField source="expense_value" />
            <PhaseField source="phase" />
            <LongTextField source="owner_type.name" sortable={false} />
            {!disabled && <SmartEditButton />}
            {!disabled && <SmartDeleteButton redirect={removeRedirect} />}
          </ReferenceListField>
        </GroupItem>
      </Group>
    </Group>
  );
};
export default ExecutiveProjectExpenseItemListField;
