import { DateTimeInput, TextInput } from "react-admin";
import {
  FormTab,
  Group,
  GroupItem,
  StateInfoField,
  ValidationSummary,
  useTabVisibility,
} from "ra-friendsofbabba";
import { List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";

import DirtyFormAlert from "../../alert/DirtyFormAlert";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    marginLeft: theme.spacing(0.6),
    padding: theme.spacing(0),
  },
}));
const PartnersField = ({ record, fieldLabel }) => {
  const classes = useStyles();
  return (
    <List
      dense
      classes={classes}
      subheader={
        <ListSubheader classes={classes}>
          {fieldLabel("partners")}
        </ListSubheader>
      }
    >
      {record?.project?.public_authority?.public_authority_partners?.map(
        (p) => (
          <ListItem classes={classes} key={p.id}>
            <ListItemText
              primary={`${p?.municipality?.name} (${p.province})`}
            />
          </ListItem>
        )
      )}
    </List>
  );
};

const ExecutiveProjectTab0 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(0);

  return (
    <FormTab {...props} label={fieldLabel("tabs.section_0")}>
      {visible && <ValidationSummary />}
      {visible && <DirtyFormAlert />}
      <Group fullWidth>
        <GroupItem>
          <TextInput disabled source="project.code" />
        </GroupItem>
        <GroupItem>
          <DateTimeInput disabled source="project.sent_transaction.created" />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem>
          <TextInput
            disabled
            source="project.public_authority.municipality.name"
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem>
          <PartnersField fieldLabel={fieldLabel} />
        </GroupItem>
      </Group>
      <StateInfoField />
    </FormTab>
  );
};
export default ExecutiveProjectTab0;
