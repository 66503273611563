import {
  DebouncedTextInput,
  Group,
  GroupItem,
  Toolbar,
  useFieldLabel,
  useSaveMutation,
} from "ra-friendsofbabba";
import { SimpleForm, required } from "react-admin";

import { useMemo } from "react";

const ExecutiveProjectLocalPartnerForm = (props) => {
  const save = useSaveMutation({ ...props });
  const fieldLabel = useFieldLabel({
    resource: "executive-project-local-partners",
  });
  const disabled = useMemo(
    () => props.record?.local_partner_id > 0,
    [props.record?.local_partner_id]
  );

  return (
    <SimpleForm
      {...props}
      toolbar={
        <Toolbar
          backTab={1}
          backReferenceTarget="executive_project_id"
          backReference="executive-projects"
        />
      }
      save={save}
    >
      <Group fullWidth>
        <GroupItem lg={8} md={10} sm={12}>
          <DebouncedTextInput
            source="name"
            helperText={fieldLabel("name.help")}
            maxLength={200}
            validate={required()}
            disabled={disabled}
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={10} md={12} sm={12}>
          <DebouncedTextInput
            source="headquarters"
            helperText={fieldLabel("headquarters.help")}
            maxLength={200}
            multiline
            validate={required()}
            disabled={disabled}
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={10} md={12} sm={12}>
          <DebouncedTextInput
            source="roles"
            helperText={fieldLabel("roles.help")}
            maxLength={500}
            multiline
            validate={required()}
          />
        </GroupItem>
      </Group>
    </SimpleForm>
  );
};
export default ExecutiveProjectLocalPartnerForm;
