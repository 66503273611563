import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import {
  Group,
  GroupItem,
  useDebounce,
  useFieldLabel,
} from "ra-friendsofbabba";

import PercentageInput from "./PercentageInput";
import React from "react";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  list: {
    "& li > div > div:first-child": {
      display: "none",
    },
  },
}));
const ExecutiveProjectLocalPartnersPercInput = ({ validate, ...props }) => {
  const classes = useStyles();
  const fieldLabel = useFieldLabel({
    resource: "executive-project-local-partners",
  });
  const didMount = useRef(false);
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const value = get(props.record, props.source);
  const debouncedValue = useDebounce(value, 3000);
  useEffect(() => {
    if (!debouncedValue) {
      return;
    }
    if (didMount.current) {
      return;
    }
    didMount.current = true;
    setTimestamp(new Date().getTime());
  }, [debouncedValue]);

  return (
    <ArrayInput {...props} className={classes.list}>
      <SimpleFormIterator
        disableAdd
        disableRemove
        disableReordering
        key={timestamp}
      >
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => (
            <Group fullWidth>
              <GroupItem>
                <TextInput
                  source={getSource("name")}
                  label={fieldLabel("name")}
                  record={scopedFormData}
                  disabled
                />
              </GroupItem>
              <GroupItem>
                <PercentageInput
                  source={getSource("budget_management_perc")}
                  label={fieldLabel("budget_management_perc")}
                  record={scopedFormData}
                  validate={validate}
                  disabled={props?.disabled}
                />
              </GroupItem>
            </Group>
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default ExecutiveProjectLocalPartnersPercInput;
