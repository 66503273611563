import { Group, GroupItem } from "ra-friendsofbabba";

import { BooleanInput } from "react-admin";
import Disposition from "./Disposition";

const Calendar = ({ translate, ...props }) => {
  return (
    <Group wrapper {...props}>
      <Group>
        <GroupItem lg={4} md={4} sm={8} xs={12}>
          <BooleanInput source="config.useNode" label="Use Node" />
        </GroupItem>
      </Group>
      <Disposition />
    </Group>
  );
};
export default Calendar;
