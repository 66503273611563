import {
  FormTab,
  ValidationSummary,
  useTabVisibility,
} from "ra-friendsofbabba";

import DirtyFormAlert from "../../alert/DirtyFormAlert";
import PrintAlert from "../../alert/PrintAlert";
import React from "react";
import { SignedDocMediaInput } from "../../input";

const ExecutiveProjectTab6 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(6);

  return (
    <FormTab {...props} label={fieldLabel("tabs.section_6")}>
      {visible && <ValidationSummary />}
      {visible && <DirtyFormAlert />}
      {visible && <PrintAlert />}
      <SignedDocMediaInput source="signed_doc_media" visible={visible} />
    </FormTab>
  );
};
export default ExecutiveProjectTab6;
