import {
  Group,
  GroupItem,
  GroupTitle,
  ProgressField,
  ReferenceListField,
  TextField,
  useFieldLabel,
  useWorkflowInput,
} from "ra-friendsofbabba";
import React, { Fragment, useMemo } from "react";

import useFormPermission from "../input/useFormPermission";

const PublicSpacesField = ({ ...props }) => {
  const fieldLabel = useFieldLabel({ ...props });
  const hasFormPermission = useFormPermission();
  const hasPublicSpacePermission = useMemo(
    () => hasFormPermission("public_spaces"),
    [hasFormPermission]
  );
  const { disabled } = useWorkflowInput({
    ...props,
  });

  return (
    <Fragment>
      <GroupTitle
        title={fieldLabel("public_spaces")}
        subTitle={fieldLabel("public_spaces.help")}
      />
      <Group {...props}>
        <GroupItem lg={12} md={12} sm={12}>
          <ReferenceListField
            tab={1}
            createLabel="ra.action.add"
            reference="public-spaces"
            target="project_id"
            mobileBreakpoint="sm"
            mobilePrimaryText={(record) => record?.name}
            modify={!disabled && hasPublicSpacePermission}
            create={false}
            remove={false}
          >
            <TextField
              source="name"
              sortBy="PublicSpaces.name"
              emptyText={fieldLabel("public_spaces.empty")}
            />
            <TextField
              source="public_space_type.name"
              sortBy="PublicSpaceTypes.name"
              emptyText={fieldLabel("public_space_type.empty")}
            />
            <ProgressField source="completion" sortable={false} />
          </ReferenceListField>
        </GroupItem>
      </Group>
    </Fragment>
  );
};

export default PublicSpacesField;
