import {
  FormTab,
  ValidationSummary,
  useTabVisibility,
} from "ra-friendsofbabba";

import DirtyFormAlert from "../../alert/DirtyFormAlert";
import { PublicAuthorityInput } from "../../input";
import React from "react";

const ProjectTab0 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(0);

  return (
    <FormTab {...props} label={fieldLabel("tabs.section_0")}>
      {visible && <ValidationSummary />}
      {visible && <DirtyFormAlert />}
      <PublicAuthorityInput
        source="public_authority"
        fullWidth
        visible={visible}
      />
    </FormTab>
  );
};
export default ProjectTab0;
