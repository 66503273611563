import { API_URL, APP_TITLE } from "../../config";
import {
  LoginPage as FobLoginPage,
  LocalLoginForm,
  ResetPasswordLinkButton,
  SpidLoginForm,
} from "ra-friendsofbabba";
import React, { useMemo } from "react";

import { Typography } from "@material-ui/core";
import logo from "../../assets/logo.png";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  alert: {
    padding: theme.spacing(1.5),
    maxWidth: 250,
    fontSize: "small",
  },
}));

const Alert = () => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Typography className={classes.alert} variant="body2">
      <span dangerouslySetInnerHTML={{ __html: translate("ra.login.alert") }} />
    </Typography>
  );
};
const LoginPage = (props) => {
  const isStaff = useMemo(
    () => props.location.search.indexOf("type=staff") > -1,
    [props.location.search]
  );
  return (
    <FobLoginPage
      {...props}
      themeOverride={theme}
      logo={<img src={logo} width={300} alt={APP_TITLE} />}
    >
      {isStaff && <LocalLoginForm />}
      <SpidLoginForm apiUrl={API_URL} signup="#/signup" />
      {isStaff && <ResetPasswordLinkButton />}
      <Alert />
    </FobLoginPage>
  );
};
export default LoginPage;
