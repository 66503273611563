import {
  DebouncedTextInput,
  Group,
  GroupItem,
  Toolbar,
  useSaveMutation,
} from "ra-friendsofbabba";
import { SimpleForm, email } from "react-admin";

import React from "react";

const ExecutiveProjectHumanResourceForm = (props) => {
  const save = useSaveMutation({ ...props });
  return (
    <SimpleForm
      {...props}
      save={save}
      toolbar={
        <Toolbar
          backTab={1}
          backReferenceTarget="executive_project_id"
          backReference="executive-projects"
        />
      }
    >
      <Group fullWidth>
        <GroupItem>
          <DebouncedTextInput source="name" maxLength={100} />
        </GroupItem>
        <GroupItem>
          <DebouncedTextInput source="surname" maxLength={100} />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem>
          <DebouncedTextInput source="phone" maxLength={100} />
        </GroupItem>
        <GroupItem>
          <DebouncedTextInput
            source="email"
            maxLength={100}
            validate={email()}
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={7}>
          <DebouncedTextInput source="membership" maxLength={100} />
        </GroupItem>
        <GroupItem lg={5}>
          <DebouncedTextInput source="role" maxLength={100} />
        </GroupItem>
      </Group>
    </SimpleForm>
  );
};
export default ExecutiveProjectHumanResourceForm;
