import {
  DebouncedDateInput,
  DebouncedTextInput,
  Group,
  GroupItem,
  GroupTitle,
  useWorkflowInput,
} from "ra-friendsofbabba";

import React from "react";
import { email } from "react-admin";
import useFormPermission from "./useFormPermission";
import { useMemo } from "react";

// import moment from "moment";
// import { useCallback } from "react";

const ExecutiveProjectPrivateInput = ({ fieldLabel, ...props }) => {
  // const today = useMemo(() => new Date(), []);
  const { disabled } = useWorkflowInput(props);
  const hasFormPermission = useFormPermission();
  const hasPermission = useMemo(
    () => hasFormPermission(props?.source),
    [hasFormPermission, props?.source]
  );
  // const futureDate = useCallback(
  //   (message = "ra.validation.future_date") =>
  //     (value) =>
  //       moment(value).isBefore(today) && message,
  //   [today]
  // );
  const visible = useMemo(
    () => props?.record?.project?.public_space?.handler === "private",
    [props]
  );
  if (!visible) {
    return null;
  }
  return (
    <Group {...props} disabled={disabled || !hasPermission} wrapper>
      <GroupTitle
        title={fieldLabel(props?.source)}
        subTitle={fieldLabel(`${props?.source}.help`)}
      />
      <Group>
        <GroupItem>
          <DebouncedTextInput source="private_name" />
        </GroupItem>
        <GroupItem>
          <DebouncedTextInput source="private_phone" />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem>
          <DebouncedTextInput
            source="private_email"
            type="email"
            validate={email()}
          />
        </GroupItem>
        <GroupItem lg={6} sm={12}>
          <DebouncedDateInput
            source="private_expiry_date"
            helperText={fieldLabel("private_expiry_date.help")}
            // validate={futureDate()}
          />
        </GroupItem>
      </Group>
    </Group>
  );
};
export default ExecutiveProjectPrivateInput;
