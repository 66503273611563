import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  required,
} from "react-admin";
import { DebouncedTextInput, Group, GroupItem } from "ra-friendsofbabba";

import React from "react";
import RichTextInput from "../../RichTextInput";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
}));
const Faq = ({ field, values, translate, ...props }) => {
  const classes = useStyles();
  const additionalProps = {};
  if (field?.is_required) {
    additionalProps.validate = required();
  }
  return (
    <ArrayInput
      {...props}
      {...additionalProps}
      source={field.code}
      label={field.name}
    >
      <SimpleFormIterator
        disableReordering
        TransitionProps={{ enter: false, exit: false }}
      >
        <FormDataConsumer>
          {({ formData, scopedFormData, getSource, ...rest }) => (
            <Group wrapper {...rest}>
              <Group>
                <GroupItem lg={10} md={12} sm={12}>
                  <DebouncedTextInput
                    label={translate(`resources.posts.fields.faq.title`)}
                    source={getSource("title")}
                    validate={required()}
                    className={classes.input}
                  />
                </GroupItem>
                <GroupItem lg={12} md={12} sm={12}>
                  <RichTextInput
                    label=""
                    source={getSource("description")}
                    validate={required()}
                    toolbar={["bold", "italic", "underline"]}
                    className={classes.input}
                  />
                </GroupItem>
              </Group>
            </Group>
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default Faq;
