let environment = "PRODUCTION";
let appUrl = `//${document.location.host}/`;
if (appUrl.endsWith(":3000/")) {
  appUrl = "http://centririsorse.local/";
  environment = "DEVELOPMENT";
} else if (document.location.href.indexOf("/candidatura") !== -1) {
  appUrl = `${appUrl}candidatura/`;
}
let recaptchaSiteKey = "";

switch (appUrl) {
  case "//centririsorse.its.engineering/":
    recaptchaSiteKey = "6Ld7GUsgAAAAAN5iUpyRa06nl3uHcSUB8434mxla";
    break;
  case "//galattica.regione.puglia.it/candidatura/":
    recaptchaSiteKey = "6LeIMcYgAAAAAEKE21BRAv3kkN7IpctbYqWiYG0J";
    break;
  case "//centririsorse.local/":
  default:
    recaptchaSiteKey = "6LekNssfAAAAABcnZL0o-OmJPRUc52L8lxYz_dex";
    break;
}

export const APP_URL = appUrl;
export const API_URL = `${APP_URL}api`;
export const APP_TITLE = "Galattica";
export const APP_SUB_TITLE = "1.1.14";

export const DRAWER_WIDTH = 260;
export const ENVIRONMENT = environment;
export const MAX_UPLOAD_FILE_SIZE = 41943040.0;
export const RECAPTCHA_SITE_KEY = recaptchaSiteKey;
