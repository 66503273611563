import {
  BudgetManagementNotesInput,
  BudgetManagementTypeInput,
  RegisterInput,
} from "../../input";
import {
  FormTab,
  ValidationSummary,
  useTabVisibility,
  useWorkflowInput,
} from "ra-friendsofbabba";
import React, { useMemo } from "react";

import { BudgetManagementPercsInput } from "../../input";
import DirtyFormAlert from "../../alert/DirtyFormAlert";
import { ExecutiveProjectExpenseItemsField } from "../../field";
import useFormPermission from "../../input/useFormPermission";

const SOURCE = "budget";
const ExecutiveProjectTab4 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(4);
  const { disabled } = useWorkflowInput({ ...props, source: SOURCE });
  const hasFormPermission = useFormPermission();
  const hasPermission = useMemo(
    () => hasFormPermission(SOURCE),
    [hasFormPermission]
  );
  return (
    <FormTab {...props} label={fieldLabel("tabs.section_4")}>
      {visible && <ValidationSummary />}
      {visible && <DirtyFormAlert />}
      <BudgetManagementTypeInput
        fullWidth
        disabled={disabled || !hasPermission}
        source="budget_management_type_id"
      />
      <BudgetManagementNotesInput
        fullWidth
        disabled={disabled || !hasPermission}
        source="budget_management_notes"
      />
      <BudgetManagementPercsInput
        fullWidth
        disabled={disabled || !hasPermission}
        source="budget_management_percs"
      />
      {/**
       * Registro un finto input per consentire al componente ValidationSummary
       * di recuperare l'elenco completo dei campi per cui ottenere eventuali
       * errori di validazione riscontrati.
       */}
      <RegisterInput source="executive_project_local_partners" />
      {visible && (
        <ExecutiveProjectExpenseItemsField
          fullWidth
          source="executive_project_expense_items"
          disabled={disabled || !hasPermission}
          fieldLabel={fieldLabel}
        />
      )}
    </FormTab>
  );
};
export default ExecutiveProjectTab4;
