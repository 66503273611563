import { LongTextField } from "ra-friendsofbabba";
import React from "react";
import { get } from "lodash";

const HtmlField = (props) => {
  const value = get(props.record, props.source);
  const strippedValue = value.replace(/(<([^>]+)>)/gi, "");
  return (
    <LongTextField
      {...props}
      record={{
        ...props.record,
        [props.source]: strippedValue,
      }}
    />
  );
};

export default HtmlField;
