import { Create, Edit, useTranslate } from "react-admin";

import Icon from "@material-ui/icons/DashboardOutlined";
import ProjectAlert from "../component/alert/ProjectAlert";
import { ProjectForm } from "../component";
import { ProjectFormProvider } from "../component/form/ProjectForm";
import { ProjectList } from "../component/list";

const ProjectTitle = ({ record }) => {
  const translate = useTranslate();
  const prefix = translate("resources.projects.name", {
    smart_count: 1,
  });
  return `${prefix} ${record?.code} (${record?.id})`;
};

const ProjectCreate = (props) => (
  <ProjectFormProvider>
    <ProjectAlert />
    <Create {...props}>
      <ProjectForm create />
    </Create>
  </ProjectFormProvider>
);

const ProjectEdit = (props) => (
  <ProjectFormProvider>
    <ProjectAlert />
    <Edit {...props} title={<ProjectTitle />}>
      <ProjectForm />
    </Edit>
  </ProjectFormProvider>
);

const config = {
  list: ProjectList,
  create: ProjectCreate,
  edit: ProjectEdit,
  icon: Icon,
  options: {
    group: "dashboard",
    roles: ["user", "admin", "guest", "region", "youth-worker"],
  },
};

export default config;
