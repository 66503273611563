import { Fragment, useMemo } from "react";
import {
  Group,
  GroupItem,
  GroupTitle,
  LongTextField,
  ReferenceListField,
  TextField,
  useFieldLabel,
  useWorkflowInput,
} from "ra-friendsofbabba";

import { DownloadButton } from "../button";
import useFormPermission from "../input/useFormPermission";

const LocalPartnersField = ({ visible, ...props }) => {
  const fieldLabel = useFieldLabel({ ...props });
  const hasFormPermission = useFormPermission();
  const hasLocalPartnerPermission = useMemo(
    () => hasFormPermission("local_partners"),
    [hasFormPermission]
  );
  const { disabled } = useWorkflowInput({
    ...props,
    source: "local_partners",
  });
  return (
    <Fragment>
      <GroupTitle
        title={fieldLabel("local_partners")}
        subTitle={fieldLabel("local_partners.help")}
      />
      <Group {...props}>
        <GroupItem lg={12} md={12} sm={12}>
          <ReferenceListField
            tab={2}
            addLabel={false}
            createLabel="ra.action.add"
            reference="local-partners"
            target="project_id"
            mobileBreakpoint="sm"
            mobilePrimaryText={"name"}
            mobileSecondaryText="headquarters"
            create={!disabled && hasLocalPartnerPermission}
            modify={!disabled && hasLocalPartnerPermission}
            remove={!disabled && hasLocalPartnerPermission}
          >
            <TextField source="name" />
            <LongTextField source="headquarters" />
            <LongTextField source="roles" />
            <DownloadButton source="support_and_commitments_letter_media" />
          </ReferenceListField>
        </GroupItem>
      </Group>
    </Fragment>
  );
};
export default LocalPartnersField;
