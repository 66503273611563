import { TextField, useTranslate } from "react-admin";

import React from "react";
import { get } from "lodash";

const PhaseField = ({ source, record, ...props }) => {
  const translate = useTranslate();
  const phase = get(record, source);
  const label = translate(`resources.phases.data.${phase}`);
  return (
    <TextField
      {...props}
      source={"label"}
      record={{
        label,
      }}
    />
  );
};
export default PhaseField;
