import React from "react";
import { ReferenceAutocompleteInput } from "ra-friendsofbabba";
import { required } from "react-admin";

const Node = ({ field, values, translate, fullWidth, ...props }) => (
  <ReferenceAutocompleteInput
    {...props}
    label={field.name}
    source={field.code}
    reference="post-nodes"
    validate={field.is_required && required()}
    filter={{ type: "node" }}
    optionText="title"
    optionValue="id"
  />
);
export default Node;
