import * as inputs from "./post/fields";

import { LoadingIndicator, SelectInput, useGetList } from "react-admin";

import { humanize } from "../../utility/strings";
import { useMemo } from "react";

const PostTypeFieldInput = (props) => {
  const { loading, data } = useGetList("post-type-fields/implemented");
  const choices = useMemo(() => {
    const keys = Object.keys(inputs);
    const receivedKeys = Object.keys(data);
    return keys
      .filter((key) => receivedKeys.includes(key))
      .map((key) => ({
        id: key,
        name: humanize(data[key]?.name),
      }));
  }, [data]);
  if (loading) {
    return <LoadingIndicator />;
  }

  return <SelectInput {...props} choices={choices} />;
};
export default PostTypeFieldInput;
