import { DebouncedTextInput } from "ra-friendsofbabba";
import React from "react";
import RichTextInput from "../../RichTextInput";
import { required } from "react-admin";

const Email = ({ field, values, translate, ...props }) => {
  if (field?.config?.type === "HTML") {
    return (
      <RichTextInput
        {...props}
        source={field.code}
        label={field.name}
        validate={field?.is_required && required()}
        toolbar={field?.config?.toolbar}
      />
    );
  }
  return (
    <DebouncedTextInput
      {...props}
      source={field.code}
      label={field.name}
      multiline
      maxLength={field?.config?.maxLength}
      validate={field?.is_required && required()}
    />
  );
};

export default Email;
