import { Fragment } from "react";
import { LongTextField } from "ra-friendsofbabba";
import { Typography } from "@material-ui/core";
import { get } from "lodash";

const PostTypeField = ({ record, source }) => (
  <Fragment>
    <LongTextField record={record} source={source} />
    <Typography variant="caption">{get(record, "code")}</Typography>
  </Fragment>
);

export default PostTypeField;
