import { Create, Edit, useTranslate } from "react-admin";

import { ExecutiveProjectExpenseItemForm } from "../component/form";

const ExecutiveProjectExpenseItemTitle = ({ record }) => {
  const translate = useTranslate();
  const prefix = translate("resources.executive-project-expense-items.name", {
    smart_count: 1,
  });
  return `${prefix} ${record?.expense_type?.name}`;
};
const ExecutiveProjectExpenseItemCreate = (props) => (
  <Create {...props}>
    <ExecutiveProjectExpenseItemForm />
  </Create>
);

const ExecutiveProjectExpenseItemEdit = (props) => (
  <Edit {...props} title={<ExecutiveProjectExpenseItemTitle />}>
    <ExecutiveProjectExpenseItemForm />
  </Edit>
);

const config = {
  create: ExecutiveProjectExpenseItemCreate,
  edit: ExecutiveProjectExpenseItemEdit,
};

export default config;
