import {
  AutocompleteArrayInput,
  ReferenceAutocompleteArrayInput,
} from "ra-friendsofbabba";

import NotImplemented from "./NotImplemented";
import { required } from "react-admin";

const formatList = (values) => {
  return values;
};
const parseList = (values) => {
  return values.map(({ name }) => name);
};
const formatChoices = (values) =>
  values?.map(({ name }) => ({ name, id: name }));
const AutocompleteArray = ({ field, values, translate, ...props }) => {
  const mode = field?.config?.mode;
  if (mode === "Table") {
    return (
      <ReferenceAutocompleteArrayInput
        {...props}
        source={field.code}
        label={field.name}
        reference={field?.config?.reference}
        optionText={field?.config?.optionText}
        optionValue={field?.config?.optionValue}
        validate={field?.is_required && required()}
      />
    );
  } else if (mode === "List") {
    return (
      <AutocompleteArrayInput
        {...props}
        source={field.code}
        label={field.name}
        choices={formatChoices(field?.config?.choices)}
        format={formatList}
        parse={parseList}
        optionText="name"
        optionValue="name"
        validate={field?.is_required && required()}
      />
    );
  } else if (mode === "Autofill") {
    return (
      <ReferenceAutocompleteArrayInput
        {...props}
        source={field.code}
        label={field.name}
        reference="post-field-data-sources"
        optionText="item"
        optionValue="id"
        filter={{ name: field?.config?.autofill }}
        validate={field?.is_required && required()}
      />
    );
  }
  return <NotImplemented field={field} translate={translate} />;
};

export default AutocompleteArray;
