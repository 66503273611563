import {
  FormTab,
  ValidationSummary,
  useTabVisibility,
  useWorkflowInput,
} from "ra-friendsofbabba";
import React, { useMemo } from "react";

import DirtyFormAlert from "../../alert/DirtyFormAlert";
import { ExecutiveProjectCronPhaseActionsField } from "../../field";
import useFormPermission from "../../input/useFormPermission";

const SOURCE = "executive_project_cron_phase_actions";
const ExecutiveProjectTab3 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(3);
  const { disabled } = useWorkflowInput({ ...props, source: SOURCE });
  const hasFormPermission = useFormPermission();
  const hasPermission = useMemo(
    () => hasFormPermission(SOURCE),
    [hasFormPermission]
  );
  return (
    <FormTab {...props} label={fieldLabel("tabs.section_3")}>
      {visible && <ValidationSummary />}
      {visible && <DirtyFormAlert />}
      {visible && (
        <ExecutiveProjectCronPhaseActionsField
          phase={1}
          source={SOURCE}
          fieldLabel={fieldLabel}
          disabled={disabled || !hasPermission}
        />
      )}
      {visible && (
        <ExecutiveProjectCronPhaseActionsField
          phase={2}
          source={SOURCE}
          fieldLabel={fieldLabel}
          disabled={disabled || !hasPermission}
        />
      )}
    </FormTab>
  );
};
export default ExecutiveProjectTab3;
