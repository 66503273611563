import { ActivityPlanInput, OperationalContactInput } from "../../input";
import {
  FormTab,
  ValidationSummary,
  useTabVisibility,
} from "ra-friendsofbabba";

import DirtyFormAlert from "../../alert/DirtyFormAlert";
import { HumanResourcesField } from "../../field";
import React from "react";

const ProjectTab3 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(3);

  return (
    <FormTab {...props} label={fieldLabel("tabs.section_3")}>
      {visible && <ValidationSummary />}
      {visible && <DirtyFormAlert />}

      <OperationalContactInput
        source="operational_contact"
        fullWidth
        visible={visible}
      />
      <HumanResourcesField
        source="human_resources"
        fullWidth
        visible={visible}
      />

      <ActivityPlanInput
        source="activity_plan.calendar"
        sections={["Calendar"]}
        fullWidth
        visible={visible}
      />
      <ActivityPlanInput
        source="activity_plan.promotion_and_monitoring"
        sections={["PromotionAndMonitoring"]}
        sub={["monitoring"]}
        fullWidth
        visible={visible}
      />
      <ActivityPlanInput
        source="activity_plan.civil_service"
        sections={["CivilService"]}
        fullWidth
        visible={visible}
      />
    </FormTab>
  );
};
export default ProjectTab3;
