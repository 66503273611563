import { Link, Typography } from "@material-ui/core";

import React from "react";
import get from "lodash/get";
import { useRecordContext } from "ra-core";

const UrlField = (props) => {
  const { className, emptyText, source, caption } = props;
  const record = useRecordContext(props);
  const value = get(record, source);
  const captionValue = get(record, caption);
  if (value == null) {
    return (
      <Typography component="span" variant="body2" className={className}>
        {emptyText}
      </Typography>
    );
  }

  return (
    <Link className={className} href={value} variant="body2">
      {captionValue || value}
    </Link>
  );
};

export default UrlField;
