import { email, required } from "react-admin";

import { DebouncedTextInput } from "ra-friendsofbabba";
import React from "react";

const Email = ({ field, values, translate, ...props }) => (
  <DebouncedTextInput
    {...props}
    source={field.code}
    label={field.name}
    type="email"
    validate={[email(), field?.is_required && required()]}
  />
);

export default Email;
