import {
  Group,
  GroupItem,
  GroupTitle,
  ReferenceListField,
  TextField,
} from "ra-friendsofbabba";

import { BooleanInput } from "react-admin";
import { Collapse } from "@material-ui/core";
import { useFormState } from "react-final-form";
import { useMemo } from "react";

const ExecutiveProjectPublicSpacesField = ({
  visible,
  fieldLabel,
  disabled,
  ...props
}) => {
  const { values } = useFormState({ subscription: { values: true } });
  const checked = useMemo(
    () => values?.is_public_space_requiring_other_spaces,
    [values]
  );
  return (
    <Group wrapper>
      <GroupItem {...props} lg={8} sm={9} md={10} xs={12}>
        <BooleanInput
          disabled={disabled}
          source="is_public_space_requiring_other_spaces"
        />
      </GroupItem>
      <Group wrapper>
        <Collapse in={checked} unmountOnExit>
          <GroupTitle title={fieldLabel(props?.source)} />
          <Group {...props}>
            <GroupItem lg={12} md={12} sm={12}>
              <ReferenceListField
                tab={2}
                addLabel={false}
                createLabel="ra.action.add"
                reference="executive-project-public-spaces"
                target="executive_project_id"
                mobileBreakpoint="sm"
                mobilePrimaryText={"name"}
                mobileSecondaryText="address"
                create={!disabled}
                modify={!disabled}
                remove={!disabled}
              >
                <TextField source="name" />
                <TextField source="address" />
              </ReferenceListField>
            </GroupItem>
          </Group>
        </Collapse>
      </Group>
    </Group>
  );
};
export default ExecutiveProjectPublicSpacesField;
