import { Create, Edit, useTranslate } from "react-admin";

import { ExecutiveProjectLocalPartnerForm } from "../component/form";

const ExecutiveProjectLocalPartnerTitle = ({ record }) => {
  const translate = useTranslate();
  const prefix = translate("resources.executive-project-local-partners.name", {
    smart_count: 1,
  });
  return `${prefix} ${record?.name}`;
};
const ExecutiveProjectLocalPartnerCreate = (props) => (
  <Create {...props}>
    <ExecutiveProjectLocalPartnerForm />
  </Create>
);

const ExecutiveProjectLocalPartnerEdit = (props) => (
  <Edit {...props} title={<ExecutiveProjectLocalPartnerTitle />}>
    <ExecutiveProjectLocalPartnerForm />
  </Edit>
);

const config = {
  create: ExecutiveProjectLocalPartnerCreate,
  edit: ExecutiveProjectLocalPartnerEdit,
};
export default config;
