import { BooleanInput, Labeled } from "react-admin";
import { Group, GroupItem } from "ra-friendsofbabba";

import React from "react";
import { humanize } from "../../utility/strings";

const OPTIONAL_FIELDS = [
  "post_source_id",
  "author_id",
  "published",
  "featured_image",
  "excerpt",
  "related_posts",
  "tags",
  "feedbacks",
  "taxonomies",
];

const PostTypeConfigInput = (props) => (
  <Labeled label="Optional fields">
    <Group wrapper {...props}>
      <Group>
        {OPTIONAL_FIELDS.map((field) => (
          <GroupItem key={field} lg={4} md={4} sm={8} xs={8}>
            <BooleanInput
              label={humanize(field)}
              source={`config.optional_fields.${field}`}
            />
          </GroupItem>
        ))}
      </Group>
    </Group>
  </Labeled>
);

export default PostTypeConfigInput;
