import { required, useDataProvider } from "react-admin";

import ImageResize from "quill-image-resize-module-react";
import Quill from "quill";
import RaRichTextInput from "ra-input-rich-text";
import { convertFile } from "ra-friendsofbabba";
import { useMemo } from "react";

Quill.register("modules/imageResize", ImageResize);

if (window.Quill === undefined) {
  // Without this, the editor will not work when user try to delete an image.
  // See: https://github.com/concrete-cc/quill-image-resize-module-react/blob/master/src/ImageResize.js#L195
  window.Quill = Quill;
}

class DragModule {
  constructor(resizer) {
    this.overlay = resizer.overlay;
    this.img = resizer.img;
    this.options = resizer.options;
    this.requestUpdate = resizer.onUpdate;
  }
  /*
    requestUpdate (passed in by the library during construction, above) can be used to let the library know that
    you've changed something about the image that would require re-calculating the overlay (and all of its child
    elements)
    For example, if you add a margin to the element, you'll want to call this or else all the controls will be
    misaligned on-screen.
 */

  /*
    onCreate will be called when the element is clicked on
    If the module has any user controls, it should create any containers that it'll need here.
    The overlay has absolute positioning, and will be automatically repositioned and resized as needed, so you can
    use your own absolute positioning and the 'top', 'right', etc. styles to be positioned relative to the element
    on-screen.
 */
  onCreate = () => {};

  /*
    onDestroy will be called when the element is de-selected, or when this module otherwise needs to tidy up.
    If you created any DOM elements in onCreate, please remove them from the DOM and destroy them here.
 */
  onDestroy = () => {};

  /*
    onUpdate will be called any time that the element is changed (e.g. resized, aligned, etc.)
    This frequently happens during resize dragging, so keep computations light while here to ensure a smooth
    user experience.
 */
  onUpdate = () => {};
}

function handleImage(dataProvider, quill) {
  const input = document.createElement("input");

  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    const rawFile = await convertFile({
      ...file,
      rawFile: file,
    });
    const response = await dataProvider.post("posts/upload-media", rawFile);
    const selection = quill.selection;
    const editor = quill.editor;
    const range = selection.getRange();

    editor.insertEmbed(
      range[0]?.index,
      "image",
      response?.data?.media?.file?.path
    );
  };
}
const RichTextInput = ({ toolbar, isRequired = false, ...props }) => {
  const dataProvider = useDataProvider();
  const container = useMemo(() => {
    let tb = [...toolbar];
    let listIndex = tb.indexOf("list");
    if (listIndex !== -1) {
      tb.splice(listIndex, 1, { list: "ordered" });
      tb.splice(listIndex + 1, 0, { list: "bullet" });
    }
    listIndex = tb.indexOf("align");
    if (listIndex !== -1) {
      tb.splice(listIndex, 1, { align: "" });
      tb.splice(listIndex + 1, 0, { align: "center" });
      tb.splice(listIndex + 2, 0, { align: "right" });
      tb.splice(listIndex + 3, 0, { align: "justify" });
      console.info(tb);
    }

    return tb;
  }, [toolbar]);
  return (
    <RaRichTextInput
      {...props}
      validate={isRequired ? required() : undefined}
      options={{
        modules: {
          toolbar: {
            container,
            handlers: {
              image: function () {
                handleImage(dataProvider, this.quill);
              },
            },
          },
          imageResize: {
            parchment: Quill.import("parchment"),
            modules: ["Resize", "DisplaySize", "Toolbar", DragModule],
          },
        },
      }}
    />
  );
};
export default RichTextInput;
