import {
  DeleteWithConfirmButton,
  Group,
  GroupItem,
  GroupTitle,
  LongTextField,
  ReferenceListField,
  useMakeRedirect,
} from "ra-friendsofbabba";
import { EditButton, TextField } from "ra-ui-materialui";
import React, { Fragment, useMemo } from "react";

import { CronMonthInput } from "../input";
import OwnerTypesField from "./OwnerTypesField";
import { useFormState } from "react-final-form";

const SmartEditButton = (props) => (
  <EditButton {...props} disabled={props?.record?.is_static} />
);
const SmartDeleteButton = (props) => (
  <DeleteWithConfirmButton {...props} disabled={props?.record?.is_static} />
);

const SmartMonthInput = ({ sortable, min, ...props }) => {
  const { values } = useFormState({ subscription: { values: true } });
  const source = useMemo(() => {
    const actions = values?.executive_project_cron_phase_actions;
    const index =
      actions?.indexOf(actions.find((v) => v.id === props?.record?.id)) || 0;
    const source = `executive_project_cron_phase_actions.${index}.${props.source}`;
    return source;
  }, [values, props]);

  return (
    <CronMonthInput
      {...props}
      source={source}
      label=""
      min={min}
      variant="outlined"
      small
    />
  );
};

const NumberField = ({ phase, record }) => (
  <TextField
    source="number"
    record={{
      number: `${phase}.${record?.index}`,
    }}
  />
);

const ExecutiveProjectCronPhaseActionsField = ({
  visible,
  fieldLabel,
  phase = 1,
  disabled,
  ...props
}) => {
  const removeRedirect = useMakeRedirect({
    ...props,
    tab: 3,
  });
  const { values } = useFormState({ subscription: { values: true } });
  const min = useMemo(() => {
    const actions = values?.executive_project_cron_phase_actions;
    const min =
      phase > 1 ? actions?.find((a) => (a.is_static = 1))?.month : 1 || 1;
    return min;
  }, [values, phase]);
  return (
    <Fragment>
      <GroupTitle title={fieldLabel(`${props?.source}_phase_${phase}`)} />
      <Group {...props}>
        <GroupItem lg={12} md={12} sm={12} xs={12}>
          <ReferenceListField
            addLabel={false}
            createLabel="ra.action.add"
            reference="executive-project-cron-phase-actions"
            target="executive_project_id"
            tab={3}
            filter={{ phase }}
            mobileBreakpoint="sm"
            mobilePrimaryText="title"
            mobileSecondaryText="brief_description"
            additionalData={{ phase, min }}
            create={!disabled}
            modify={false}
            remove={false}
          >
            <NumberField phase={phase} sortable={false} />
            <LongTextField source="title" width={200} sortable={false} />
            <OwnerTypesField source="owner_types" sortable={false} />
            <SmartMonthInput
              source="month"
              sortable={false}
              min={min}
              disabled={disabled}
            />
            {!disabled && <SmartEditButton />}
            {!disabled && <SmartDeleteButton redirect={removeRedirect} />}
          </ReferenceListField>
        </GroupItem>
      </Group>
    </Fragment>
  );
};

export default ExecutiveProjectCronPhaseActionsField;
