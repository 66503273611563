import { Typography } from "@material-ui/core";
import { get } from "lodash";

const OwnerTypesField = ({ source, record }) => (
  <Typography variant="body2" style={{ width: 200 }}>
    {(get(record, source, []) || []).map((v) => v.name).join(", ") || null}
  </Typography>
);

export default OwnerTypesField;
