import { Create, Edit, useTranslate } from "react-admin";

import { ExecutiveProjectForm } from "../component";
import { ExecutiveProjectList } from "../component/list";
import Icon from "@material-ui/icons/DashboardOutlined";

const ExecutiveProjectTitle = ({ record }) => {
  const translate = useTranslate();
  const prefix = translate("resources.executive-projects.name", {
    smart_count: 1,
  });
  return `${prefix} ${record?.project?.code} (${record?.id})`;
};

const ExecutiveProjectCreate = (props) => (
  <Create {...props}>
    <ExecutiveProjectForm create />
  </Create>
);

const ExecutiveProjectEdit = (props) => (
  <Edit {...props} title={<ExecutiveProjectTitle />}>
    <ExecutiveProjectForm />
  </Edit>
);

const config = {
  list: ExecutiveProjectList,
  create: ExecutiveProjectCreate,
  edit: ExecutiveProjectEdit,
  icon: Icon,
  options: {
    group: "dashboard",
    roles: ["user", "admin", "guest", "region", "youth-worker"],
  },
};

export default config;
