import {
  DebouncedTextInput,
  Group,
  GroupItem,
  GroupTitle,
  MediaInput,
  useWorkflowInput,
} from "ra-friendsofbabba";
import React, { useMemo } from "react";
import { email, useNotify } from "react-admin";

import { useCallback } from "react";
import useFormPermission from "./useFormPermission";

const ExecutiveProjectCivilOperatorInput = ({ fieldLabel, ...props }) => {
  const notify = useNotify();
  const { disabled } = useWorkflowInput(props);
  const handleRejection = useCallback(
    () => notify("ra.message.file_rejected", { type: "error" }),
    [notify]
  );
  const hasFormPermission = useFormPermission();
  const hasPermission = useMemo(
    () => hasFormPermission(props?.source),
    [hasFormPermission, props?.source]
  );
  const visible = useMemo(() => props?.record?.is_civil_service_ready, [props]);
  if (!visible) {
    return null;
  }
  return (
    <Group {...props} disabled={disabled || !hasPermission} wrapper>
      <GroupTitle
        title={fieldLabel("civil_operator")}
        subTitle={fieldLabel("civil_operator.help")}
      />
      <Group>
        <GroupItem lg={5}>
          <DebouncedTextInput source="civil_operator_name" maxLength={50} />
        </GroupItem>
        <GroupItem lg={5}>
          <DebouncedTextInput source="civil_operator_phone" maxLength={30} />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem lg={5}>
          <DebouncedTextInput
            source="civil_operator_email"
            type="email"
            maxLength={100}
            validate={email()}
          />
        </GroupItem>
        <GroupItem lg={5}>
          <DebouncedTextInput source="civil_operator_company" maxLength={100} />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem lg={10}>
          <DebouncedTextInput
            source="civil_operator_motivations"
            maxLength={1000}
            multiline
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={12} md={12} sm={12}>
          <MediaInput
            title="filename"
            source={"civil_operator_curriculum_media"}
            isRequired
            accept=".pdf"
            options={{
              onDropRejected: handleRejection,
            }}
          />
        </GroupItem>
      </Group>
    </Group>
  );
};
export default ExecutiveProjectCivilOperatorInput;
