import { useCallback, useMemo } from "react";

import { get } from "lodash";
import { useFormState } from "react-final-form";
import { usePermissions } from "ra-core";

const useFormPermission = (roles = ["admin"]) => {
  const formState = useFormState({ subscription: { values: true } });
  const formPermissions = get(formState.values, "permissions", []);
  const transactionState = get(formState.values, "transaction.state", "draft");
  const { loaded, permissions } = usePermissions();
  const isAdmin = loaded && permissions && permissions(roles);
  const { firstPhaseStates, wildcardFields } = useMemo(
    () => ({
      firstPhaseStates: ["draft", "integrate"],
      wildcardFields: [], // ["public_spaces"],
    }),
    []
  );
  return useCallback(
    (permissionName) =>
      isAdmin ||
      ((formPermissions.length === 0 ||
        formPermissions.find((p) => p.name === permissionName) !== undefined) &&
        firstPhaseStates.indexOf(transactionState) !== -1) ||
      (wildcardFields.indexOf(permissionName) !== -1 &&
        firstPhaseStates.indexOf(transactionState) === -1),
    [
      formPermissions,
      firstPhaseStates,
      wildcardFields,
      isAdmin,
      transactionState,
    ]
  );
};

export default useFormPermission;
