import { Alert, AlertTitle } from "ra-friendsofbabba";
import { CircularProgress, Typography, makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";
import { useQueryWithStore, useTranslate } from "react-admin";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

const TimeWindowAlert = () => {
  const classes = useStyles();
  const translate = useTranslate();

  const { loading, loaded, data } = useQueryWithStore({
    type: "get",
    resource: "project-time-windows/status",
  });
  const { color, title, description, startAt, endAt, status } = useMemo(
    () => ({
      color: data?.active === null ? "warning" : "success",
      title:
        data?.active?.title ||
        data?.next?.title ||
        translate("ra.title.no_time_window"),
      description:
        data?.active?.description ||
        data?.next?.description ||
        translate("ra.message.no_time_window"),
      startAt: data?.active?.start_at
        ? moment(data?.active?.start_at).format(translate("app.date.long"))
        : moment(data?.next?.start_at).format(translate("app.date.long")),
      endAt: data?.active?.end_at
        ? moment(data?.active?.end_at).format(translate("app.date.long"))
        : moment(data?.next?.start_at).format(translate("app.date.long")),
      status: data?.active ? "running" : data?.next ? "planned" : "none",
    }),
    [data, translate]
  );
  return (
    <Alert color={color} elevation={1} className={classes.root}>
      {loading && <CircularProgress />}
      {loaded && title && (
        <AlertTitle>
          {title}
          &nbsp;
          <Typography component="span" variant="caption">
            ({translate(`ra.status.time_window.${status}`)})
          </Typography>
        </AlertTitle>
      )}
      {loaded && description && (
        <Typography
          variant="body2"
          component="div"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {loaded && status !== "none" && (
        <Typography variant="subtitle1">{`${startAt} - ${endAt}`}</Typography>
      )}
    </Alert>
  );
};

export default TimeWindowAlert;
