import { Group, useFieldLabel, useWorkflowInput } from "ra-friendsofbabba";
import React, { useCallback, useMemo } from "react";

import PublicSpaceGroup1 from "./group/PublicSpaceGroup1";
import PublicSpaceGroup2 from "./group/PublicSpaceGroup2";
import PublicSpaceGroup3 from "./group/PublicSpaceGroup3";
import useFormPermission from "./useFormPermission";

const PublicSpaceInput = (props) => {
  const { disabled } = useWorkflowInput(props);
  const fieldLabel = useFieldLabel({ resource: "public-spaces" });
  const fieldSource = useCallback(
    (source) => `${props.source}.${source}`,
    [props.source]
  );
  const hasFormPermission = useFormPermission();
  const hasPermission = useMemo(
    () => hasFormPermission(props.source),
    [hasFormPermission, props.source]
  );
  return (
    <Group wrapper {...props} {...{ fieldLabel, fieldSource }}>
      <PublicSpaceGroup1 disabled={disabled || !hasPermission} />
      <PublicSpaceGroup2 disabled={disabled || !hasPermission} />
      <PublicSpaceGroup3 disabled={disabled || !hasPermission} />
    </Group>
  );
};

export default PublicSpaceInput;
