import {
  DebouncedTextInput,
  Group,
  GroupItem,
  ReferenceSelectInput,
  Toolbar,
  useFieldLabel,
  useSaveMutation,
} from "ra-friendsofbabba";
import { SimpleForm, required } from "react-admin";

import { MoneyInput } from "../input";
import PhaseInput from "../input/PhaseInput";

const ExecutiveProjectExpenseItemForm = (props) => {
  const save = useSaveMutation({ ...props });
  const fieldLabel = useFieldLabel(props);
  return (
    <SimpleForm
      {...props}
      toolbar={
        <Toolbar
          backTab={4}
          backReferenceTarget="executive_project_id"
          backReference="executive-projects"
        />
      }
      save={save}
    >
      <Group fullWidth>
        <GroupItem lg={12} md={12} sm={12}>
          <DebouncedTextInput
            source="description"
            multiline
            maxLength={1000}
            validate={required()}
          />
        </GroupItem>
        <GroupItem>
          <MoneyInput
            source="expense_value"
            validate={required()}
            helperText={fieldLabel("expense_value.help")}
          />
        </GroupItem>
        <GroupItem>
          <PhaseInput source="phase" validate={required()} />
        </GroupItem>
        <GroupItem lg={12} md={12} sm={12}>
          <ReferenceSelectInput
            reference="owner-types"
            source="owner_type_id"
            validate={required()}
          />
        </GroupItem>
      </Group>
    </SimpleForm>
  );
};
export default ExecutiveProjectExpenseItemForm;
