import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  TabbedForm,
  useGetIdentity,
  useNotify,
  usePermissions,
  useRedirect,
  useRefresh,
} from "react-admin";
import { Toolbar, useFieldLabel, useSaveMutation } from "ra-friendsofbabba";

import { DRAWER_WIDTH } from "../../config";
import { PrintButton } from "../button";
import ProjectTab0 from "./tab/ProjectTab0";
import ProjectTab1 from "./tab/ProjectTab1";
import ProjectTab2 from "./tab/ProjectTab2";
import ProjectTab3 from "./tab/ProjectTab3";
import ProjectTab4 from "./tab/ProjectTab4";
import ProjectTab5 from "./tab/ProjectTab5";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

export const ProjectFormContext = createContext({});
export const ProjectFormProvider = ({ children }) => {
  const [record, setRecord] = useState({});

  return (
    <ProjectFormContext.Provider value={{ record, setRecord }}>
      {children}
    </ProjectFormContext.Provider>
  );
};

export const useCanSubmit = ({ tab = false, record }) =>
  useMemo(() => {
    if (!record || !record?.id) {
      return false;
    }
    const validationErrors = record?.validationErrors || {};
    const errors = Object.keys(validationErrors);
    const count = errors.length;
    const valid = count === 0 || errors[0] === "signed_doc_media";
    const state = record?.transaction?.state;
    return tab ? valid && state !== "draft" : valid;
  }, [record, tab]);

const useStyles = makeStyles((theme) => ({
  root: ({ sidebarOpen, sidebarWidth }) => ({
    "& .MuiTabs-scroller.MuiTabs-fixed": {
      overflowX: "auto !important",
      [theme.breakpoints.up("md")]: {
        maxWidth: sidebarOpen
          ? `calc(100vw - ${sidebarWidth + 64}px)`
          : "calc(100vw - 65px)",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: sidebarOpen
          ? `calc(100vw - ${sidebarWidth + 64}px)`
          : "calc(100vw - 65px)",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "calc(100vw - 17px)",
      },
    },
  }),
  help: {
    marginBottom: theme.spacing(2),
  },
}));

const defaultInitialValues = {
  activity_plan: {
    id: null,
  },
  operational_contact: {
    id: null,
  },
  public_space: {
    id: null,
  },
};

const ProjectToolbar = ({ ...props }) => {
  const stateFilter = useMemo(() => {
    if (!props?.record || !props?.record?.id) {
      return () => true;
    }
    if (!props?.record || !props?.record?.validationErrors) {
      return () => false;
    }
    const validationErrors = props?.record?.validationErrors || {};
    const count = Object.keys(validationErrors).length;
    const valid = count === 0 || validationErrors[0] === "signed_doc_media";

    if (valid) {
      return () => true;
    }
    return (state) => state.code !== "validated";
  }, [props?.record]);
  return (
    <Toolbar {...props} stateFilter={stateFilter} maxButtonsToDisplay={2}>
      <PrintButton
        layout="form"
        label="resources.projects.buttons.print_form"
        confirm={false}
      />
    </Toolbar>
  );
};

const ProjectForm = ({ create = false, ...props }) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const save = useSaveMutation({
    ...props,
    onSuccess:
      props.record.id > 0
        ? (values, response) => {
            const prevState = values?.data?.transaction?.state;
            const nextState = values?.data?.state;
            const loop = nextState === prevState;
            refresh();
            if (prevState === "draft" && nextState === "validated") {
              // Passing from 'Draft' to' 'Validated' means that user have to
              // upload signed document. We try to simplify this process by redirecting
              // him to the correct tab where new required fields are located.
              setTimeout(() => redirect(`/projects/${response.id}/4`), 1000);
            } else if (
              prevState === "validated" &&
              nextState === "validation_canceled"
            ) {
              setTimeout(() => redirect(`/projects/${response.id}`), 1000);
            }
            notify(
              `resources.projects.notify.${nextState}_${
                loop ? "loop" : "success"
              }`
            );
          }
        : undefined,
  });
  const { setRecord } = useContext(ProjectFormContext);
  const sidebarOpen = useSelector((state) => state?.admin?.ui?.sidebarOpen);
  const sidebarWidth = DRAWER_WIDTH;
  const classes = useStyles({ sidebarOpen, sidebarWidth });
  const fieldLabel = useFieldLabel({ ...props });

  const { loaded, permissions } = usePermissions();
  const isAdmin = useMemo(
    () => loaded && permissions && permissions(["admin", "region"]),
    [loaded, permissions]
  );
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const { identity, loading } = useGetIdentity();
  useEffect(() => {
    if (!loading && identity != null) {
      setInitialValues((prevState) => ({
        ...prevState,
        public_authority: {
          ...prevState?.public_authority,
          accountable_name: identity.name,
          accountable_surname: identity.surname,
          accountable_fiscal_code: identity.fiscal_code,
          accountable_email: identity.email,
        },
        activity_plan: {
          ...prevState?.activity_plan,
          civil_referent: `${identity.name} ${identity.surname}`,
        },
        public_space: {
          ...prevState?.public_space,
          name: "",
          address: "",
          public_space_type_id: null,
          municipality_id: null,
        },
        operational_contact: {
          fullname: "",
        },
      }));
    }
  }, [identity, loading]);
  const canSubmit = useCanSubmit({ tab: true, ...props });
  useEffect(() => setRecord(props?.record), [props?.record, setRecord]);
  return (
    <TabbedForm
      {...props}
      className={classes.root}
      warnWhenUnsavedChanges
      toolbar={<ProjectToolbar useWorkflow maxButtonsToDisplay={2} />}
      initialValues={initialValues}
      save={save}
    >
      <ProjectTab0 fieldLabel={fieldLabel} />
      <ProjectTab1 fieldLabel={fieldLabel} />
      <ProjectTab2 fieldLabel={fieldLabel} />
      <ProjectTab3 fieldLabel={fieldLabel} />
      {canSubmit && <ProjectTab4 fieldLabel={fieldLabel} />}
      {isAdmin && <ProjectTab5 fieldLabel={fieldLabel} />}
    </TabbedForm>
  );
};

export default ProjectForm;
