import { SelectInput } from "react-admin";
import { useMemo } from "react";

const MONTHS = Array.from({ length: 18 }, (v, k) => k + 1).map((v) => ({
  id: v,
  name: `M${v}`,
}));

const CronMonthInput = ({ small, min, ...props }) => {
  const max = props?.record?.phase > 1 ? 18 : 12;
  const months = useMemo(
    () => MONTHS.filter((m) => m.id >= min && m.id <= max),
    [min, max]
  );
  const styles = small
    ? { minWidth: "80px", maxWidth: "80px", width: "80px" }
    : undefined;
  return <SelectInput {...props} choices={months} style={styles} />;
};

export default CronMonthInput;
