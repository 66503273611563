import { DebouncedTextInput } from "ra-friendsofbabba";
import React from "react";
import { url } from "../../utility/validation";
import { useFormState } from "react-final-form";

const PostLinkInput = (props) => {
  const { values } = useFormState({ subscription: { values: true } });
  return (
    <DebouncedTextInput
      {...props}
      validate={values?.link_type === "Url" ? url() : undefined}
      maxLength={values?.link_type === "Url" ? 2048 : 20}
    />
  );
};

export default PostLinkInput;
