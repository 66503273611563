import { Create, Edit, useTranslate } from "react-admin";

import { ExecutiveProjectCronPhaseActionForm } from "../component/form";

const ExecutiveProjectCronPhaseActionTitle = ({ record }) => {
  const translate = useTranslate();
  const prefix = translate(
    "resources.executive-project-cron-phase-actions.name",
    {
      smart_count: 1,
    }
  );
  return `${prefix} ${record?.title}`;
};
const ExecutiveProjectCronPhaseActionCreate = (props) => (
  <Create {...props}>
    <ExecutiveProjectCronPhaseActionForm />
  </Create>
);

const ExecutiveProjectCronPhaseActionEdit = (props) => (
  <Edit {...props} title={<ExecutiveProjectCronPhaseActionTitle />}>
    <ExecutiveProjectCronPhaseActionForm />
  </Edit>
);

const config = {
  create: ExecutiveProjectCronPhaseActionCreate,
  edit: ExecutiveProjectCronPhaseActionEdit,
};

export default config;
