import {
  ArrayInput,
  RadioButtonGroupInput,
  SimpleFormIterator,
  required,
} from "react-admin";
import {
  DebouncedTextInput,
  Group,
  GroupItem,
  ReferenceAutocompleteInput,
} from "ra-friendsofbabba";
import React, { useMemo } from "react";

import Disposition from "./Disposition";
import { useFormState } from "react-final-form";

const ListMode = ["Table", "List", "Autofill"].map((id) => ({
  id,
  name: id,
}));
const List = ({ translate, ...props }) => {
  const { values } = useFormState({ subscription: { values: true } });
  const customRequired = useMemo(
    () => (values?.config?.allowCustom !== true ? required() : undefined),
    [values?.config?.allowCustom]
  );
  return (
    <Group wrapper {...props}>
      <Group>
        <GroupItem lg={12}>
          <RadioButtonGroupInput
            label={translate("post.fields.config.list.mode")}
            source="config.mode"
            choices={ListMode}
          />
        </GroupItem>
      </Group>
      {values?.config?.mode === "Autofill" && (
        <Group>
          <GroupItem lg={12}>
            <ReferenceAutocompleteInput
              label={translate("post.fields.config.list.autofill")}
              reference="post-field-data-sources/names"
              optionText="name"
              optionValue="id"
              source="config.autofill"
              validate={required()}
            />
          </GroupItem>
        </Group>
      )}
      {values?.config?.mode === "Table" && (
        <Group>
          <GroupItem lg={4} md={4}>
            <ReferenceAutocompleteInput
              label={translate("post.fields.config.list.reference")}
              source="config.reference"
              reference="post-type-fields/source-tables"
              optionText="name"
              validate={required()}
            />
          </GroupItem>
          <GroupItem lg={4} md={4}>
            <DebouncedTextInput
              label={translate("post.fields.config.list.optionValue")}
              source="config.optionValue"
              validate={required()}
            />
          </GroupItem>
          <GroupItem lg={4} md={4}>
            <DebouncedTextInput
              label={translate("post.fields.config.list.optionText")}
              source="config.optionText"
              validate={required()}
            />
          </GroupItem>
        </Group>
      )}
      {values?.config?.mode === "List" && (
        <Group>
          <GroupItem lg={12} md={12} sm={12} xs={12}>
            <ArrayInput
              key={values?.config?.allowCustom}
              source="config.choices"
              label={translate("post.fields.config.list.choices")}
              fullWidth
              validate={customRequired}
            >
              <SimpleFormIterator disableReordering>
                <DebouncedTextInput label="Name" source="name" />
              </SimpleFormIterator>
            </ArrayInput>
          </GroupItem>
        </Group>
      )}
      <Disposition />
    </Group>
  );
};

export default List;
