import { DebouncedTextInput } from "ra-friendsofbabba";
import React from "react";
import { required } from "react-admin";

const validate =
  (message = "ra.validation.phone") =>
  (value) => {
    if (!value) {
      return undefined;
    }
    const regex = /^(\+?1\s?)?(\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (!regex.test(value)) {
      return message;
    }
  };

const Phone = ({ field, values, translate, ...props }) => (
  <DebouncedTextInput
    {...props}
    source={field.code}
    label={field.name}
    validate={[validate(), field?.is_required && required()]}
  />
);

export default Phone;
