import React from "react";
import { TimeInput } from "ra-friendsofbabba";
import { required } from "react-admin";

const Time = ({ field, values, translate, ...props }) => (
  <TimeInput
    {...props}
    source={field.code}
    label={field.name}
    validate={field?.is_required && required()}
  />
);

export default Time;
