import React, { useMemo } from "react";

import TreeCheckboxInput from "./TreeCheckboxInput";
import { useGetList } from "react-admin";

const flatten = (node) => ({
  nodeId: node.id.toString(),
  parentId: node.parent_id?.toString(),
  label: node.name,
  children: node.children?.map(flatten),
});

const PostTaxonomiesInput = ({ ...props }) => {
  const { loading, loaded, data } = useGetList(
    "post-taxonomies",
    {
      pagination: { page: 1, perPage: 100 },
    },
    { field: "name", order: "ASC" },
    { tree: true }
  );
  const nodes = useMemo(() => {
    if (loading || !loaded) {
      return [];
    }
    const roots = Object.keys(data).map((key) => data[key]);
    const nodes = roots.map(flatten);
    return nodes;
  }, [loading, loaded, data]);

  return <TreeCheckboxInput {...props} nodes={nodes} />;
};

export default PostTaxonomiesInput;
