import { RadioButtonGroupInput, ReferenceInput } from "react-admin";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    "& legend": {
      marginBottom: theme.spacing(1),
    },
  },
}));
const BudgetManagementTypeInput = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ReferenceInput
        {...props}
        reference="budget-management-types"
        optionText="name"
        sort={{ field: "id", order: "ASC" }}
      >
        <RadioButtonGroupInput optionText="name" />
      </ReferenceInput>
    </div>
  );
};
export default BudgetManagementTypeInput;
