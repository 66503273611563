import { Create, Edit, useTranslate } from "react-admin";

import { ExecutiveProjectHumanResourceForm } from "../component/form";

const ExecutiveProjectHumanResourceTitle = ({ record }) => {
  const translate = useTranslate();
  const prefix = translate("resources.executive-project-human-resources.name", {
    smart_count: 1,
  });
  return `${prefix} ${record?.name} ${record?.surname}`;
};
const ExecutiveProjectHumanResourceCreate = (props) => (
  <Create {...props}>
    <ExecutiveProjectHumanResourceForm />
  </Create>
);

const ExecutiveProjectHumanResourceEdit = (props) => (
  <Edit {...props} title={<ExecutiveProjectHumanResourceTitle />}>
    <ExecutiveProjectHumanResourceForm />
  </Edit>
);

const config = {
  create: ExecutiveProjectHumanResourceCreate,
  edit: ExecutiveProjectHumanResourceEdit,
};

export default config;
