import { useCallback, useEffect } from "react";

import { getToken } from "ra-friendsofbabba";
import { useSafeSetState } from "ra-core";

export const clearAuth = () => {
  ["token", "roles", "username", "profile", "email"].forEach((param) => {
    localStorage.removeItem(`admin_${param}`);
    localStorage.removeItem(param);
  });
};
export const validateJson = (data) => {
  if (!data?.success && data?.data?.code === 401) {
    clearAuth();
    document.location.reload();
  }
  return data;
};
const load = async ({ apiUrl, token }) => {
  let headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);

  return fetch(`${apiUrl}/layout-data`, {
    headers,
  })
    .then((response) => response.json())
    .then(validateJson)
    .then(({ data }) => data);
};
const useLayoutData = ({ apiUrl }) => {
  const [{ loaded, loading, data, token }, setData] = useSafeSetState({
    loading: false,
    loaded: false,
    data: [],
    token: getToken(),
  });
  const handleLogin = useCallback(
    (e) => {
      if (token !== e.value) {
        setData((d) => ({ ...d, token: e.value, loaded: false }));
      }
    },
    [setData, token]
  );
  useEffect(
    () => document.addEventListener("login", handleLogin),
    [handleLogin]
  );

  useEffect(() => {
    const loadAll = ({ apiUrl, token }) => {
      if (loaded || loading || token === null) {
        return;
      }

      setData({ loading: true });
      load({ apiUrl, token }).then((data) =>
        setData({ loaded: true, loading: false, data })
      );
    };
    const token = getToken();
    loadAll({ apiUrl, token });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl, token]);

  return { loaded, loading, data };
};
export default useLayoutData;
