import {
  BooleanInput,
  DateField,
  FilterButton,
  List,
  SimpleList,
  TextField,
  TopToolbar,
  usePermissions,
} from "react-admin";
import {
  Datagrid,
  EditButton,
  ListEmpty,
  SearchInput,
  StateCollectionInput,
  StateInput,
} from "ra-friendsofbabba";
import React, { useMemo } from "react";

import MunicipalityInput from "../input/MunicipalityInput";
import ProvinceInput from "../input/ProvinceInput";
import { useMediaQuery } from "@material-ui/core";

const Actions = () => {
  return (
    <TopToolbar>
      <FilterButton />
    </TopToolbar>
  );
};

const ExecutiveProjectList = (props) => {
  const { loaded, permissions } = usePermissions();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isAdmin = useMemo(
    () => loaded && permissions("admin"),
    [loaded, permissions]
  );
  return (
    <List
      {...props}
      empty={<ListEmpty create={false} />}
      actions={<Actions />}
      bulkActionButtons={isAdmin}
      filters={[
        <SearchInput source="q" alwaysOn />,
        <StateInput source="state" alwaysOn />,
        <ProvinceInput source="province" />,
        <MunicipalityInput
          source="municipality_id"
          deps={{ province: "province" }}
        />,
        <BooleanInput
          source="is_civil_service_ready"
          defaultChecked
          defaultValue={true}
        />,
      ]}
    >
      {isMobile ? (
        <SimpleList
          primaryText={(record) => record?.project?.code}
          secondaryText={(record) =>
            `${record?.project?.public_authority?.municipality?.name} (${record?.project?.public_authority?.province})`
          }
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="project.code" sortBy="Projects.code" />
          <TextField source="project.user.name" sortBy="UserProfiles.name" />
          <TextField
            source="project.public_authority.province"
            sortBy="PublicAuthorities.province"
          />
          <TextField
            source="project.public_authority.municipality.name"
            sortBy="Municipalities.name"
          />
          {!isSmall && (
            <DateField
              source="modified"
              showTime
              sortBy="ExecutiveProjects.modified"
            />
          )}
          <StateCollectionInput source="state" readonly={!isAdmin} />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

export default ExecutiveProjectList;
