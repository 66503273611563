import React, { Fragment, useMemo } from "react";

import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { get } from "lodash";
import moment from "moment";
import { useTranslate } from "react-admin";

const DateTimeRangeField = ({
  fromSource,
  toSource,
  record,
  component,
  mode = "full",
  basePath,
  ...props
}) => {
  const translate = useTranslate();
  const { from, to, days } = useMemo(
    () => ({
      from: moment(get(record, fromSource)).format(translate("app.date.long")),
      to: moment(get(record, toSource)).format(translate("app.date.long")),
      days: moment(get(record, toSource)).diff(
        moment(get(record, fromSource)),
        "days"
      ),
    }),
    [record, fromSource, toSource, translate]
  );
  return (
    <Fragment>
      {(mode === "full" || mode === "range") && (
        <Typography component="span" variant="body2" {...props}>
          {from} - {to}
        </Typography>
      )}
      {(mode === "full" || mode === "days") && (
        <Typography component="span" variant="body2" {...props} display="block">
          {translate("app.days", { days })}
        </Typography>
      )}
    </Fragment>
  );
};
DateTimeRangeField.propTypes = {
  mode: PropTypes.oneOf(["full", "range", "days"]),
};

export default DateTimeRangeField;
