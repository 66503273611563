import { Calendar, Disposition, List, Money, Text } from "./post/fields/config";

import React from "react";
import { useFormState } from "react-final-form";
import { useMemo } from "react";
import { useTranslate } from "react-admin";

const TYPES = {
  Autocomplete: List,
  AutocompleteArray: List,
  Select: List,
  SelectArray: List,
  Radio: List,
  Text: Text,
  TextBlocks: Disposition,
  BusinessTime: Disposition,
  Phone: Disposition,
  Email: Disposition,
  Url: Disposition,
  Map: Disposition,
  Boolean: Disposition,
  Date: Disposition,
  DateTime: Disposition,
  Time: Disposition,
  Calendar: Calendar,
  SimpleUserList: Disposition,
  Money,
  Faq: Disposition,
  Node: Disposition,
};

const PostTypeFieldConfigInput = (props) => {
  const { values } = useFormState({ subscription: { values: true } });
  const Component = useMemo(() => TYPES[values.type], [values.type]);
  const translate = useTranslate();
  return Component ? <Component {...props} translate={translate} /> : null;
};

export default PostTypeFieldConfigInput;
