import { DebouncedTextInput, Group, GroupItem } from "ra-friendsofbabba";
import React, { useMemo } from "react";

import { BooleanInput } from "react-admin";
import { Collapse } from "@material-ui/core";
import { useFormState } from "react-final-form";

const PublicSpaceSurveyInput = ({
  source,
  disabled,
  notesSource,
  fieldLabel,
  ...props
}) => {
  const { values } = useFormState({ subscription: { values: true } });
  const checked = useMemo(() => values[source], [values, source]);

  return (
    <Group wrapper>
      <Group {...props}>
        <GroupItem lg={8} md={9} sm={10} xs={12}>
          <BooleanInput
            source={source}
            label={fieldLabel(source)}
            disabled={disabled}
          />
        </GroupItem>
      </Group>
      <Collapse in={checked} unmountOnExit>
        <Group {...props}>
          <GroupItem {...props} lg={8} md={9} sm={10} xs={12}>
            <DebouncedTextInput
              source={notesSource}
              label={fieldLabel(notesSource)}
              maxLength={1000}
              multiline
              disabled={disabled}
            />
          </GroupItem>
        </Group>
      </Collapse>
    </Group>
  );
};

export default PublicSpaceSurveyInput;
