import { SlugInput as FobSlugInput } from "ra-friendsofbabba";
import React from "react";

const slugify = (str) =>
  str
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/&/g, "-y-") // Replace & with 'and'
    .replace(/\\-\\-+/g, "-"); // Replace multiple - with single -

const SlugInput = (props) => <FobSlugInput {...props} slugify={slugify} />;
export default SlugInput;
