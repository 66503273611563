import React from "react";
import { useFormState } from "react-final-form";
import { useGetOne } from "react-admin";

const PostInputSecureContent = ({
  component,
  postTypeId,
  components,
  ...props
}) => {
  const Component = components[component];
  const postType = useGetOne("post-types", postTypeId);
  const isVisible =
    postType?.data?.config?.optional_fields?.[props?.source] !== false;
  return isVisible && Component ? <Component {...props} /> : null;
};

const PostInput = (props) => {
  const { values } = useFormState({ subscription: { values: true } });
  return values?.post_type_id > 0 ? (
    <PostInputSecureContent {...props} postTypeId={values?.post_type_id} />
  ) : null;
};

export default PostInput;
