import { DateInput, required } from "react-admin";

import React from "react";

const Email = ({ field, values, translate, ...props }) => (
  <DateInput
    {...props}
    source={field.code}
    label={field.name}
    validate={field?.is_required && required()}
  />
);

export default Email;
