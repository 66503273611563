import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  required,
} from "react-admin";
import {
  DateTimeInput as FobDateTimeInput,
  Group,
  GroupItem,
  ReferenceAutocompleteInput,
} from "ra-friendsofbabba";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    paddingRight: theme.spacing(1),
    display: "inline-block",
  },
}));
const DateTimeInput = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.input}>
      <FobDateTimeInput {...props} />
    </div>
  );
};
const Calendar = ({ field, values, translate, ...props }) => {
  const additionalProps = {};
  if (field?.is_required) {
    additionalProps.validate = required();
  }
  return (
    <ArrayInput
      {...props}
      {...additionalProps}
      source={field.code}
      label={field.name}
      helperText={translate(`resources.posts.fields.calendar.help`)}
    >
      <SimpleFormIterator
        disableReordering
        TransitionProps={{ enter: false, exit: false }}
      >
        <FormDataConsumer>
          {({ formData, scopedFormData, getSource, ...rest }) => (
            <Group wrapper {...rest}>
              <Group>
                {field?.config?.useNode && (
                  <GroupItem lg={4} md={4} sm={8} xs={8}>
                    <ReferenceAutocompleteInput
                      label={translate("post.fields.config.calendar.node")}
                      source={getSource("node")}
                      reference="post-nodes"
                      validate={required()}
                      filter={{ type: "node" }}
                      optionText="title"
                      optionValue="id"
                    />
                  </GroupItem>
                )}
                <GroupItem
                  lg={field?.config?.useNode ? 8 : 12}
                  md={field?.config?.useNode ? 8 : 12}
                  sm={12}
                  xs={12}
                >
                  <DateTimeInput
                    label={translate("post.fields.config.calendar.start")}
                    source={getSource("start")}
                    validate={required()}
                  />
                  <DateTimeInput
                    label={translate("post.fields.config.calendar.end")}
                    source={getSource("end")}
                  />
                </GroupItem>
              </Group>
            </Group>
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default Calendar;
