import {
  FormTab,
  ValidationSummary,
  useTabVisibility,
} from "ra-friendsofbabba";

import { ActivityPlanInput } from "../../input";
import DirtyFormAlert from "../../alert/DirtyFormAlert";
import { LocalPartnersField } from "../../field";
import React from "react";

const ProjectTab2 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(2);

  return (
    <FormTab {...props} label={fieldLabel("tabs.section_2")}>
      {visible && <ValidationSummary />}
      {visible && <DirtyFormAlert />}

      <ActivityPlanInput
        source="activity_plan.plan"
        sections={["Plan"]}
        fullWidth
        visible={visible}
      />
      <ActivityPlanInput
        source="activity_plan.promotion_and_monitoring"
        sections={["PromotionAndMonitoring"]}
        sub={["promotion"]}
        fullWidth
        visible={visible}
      />
      <ActivityPlanInput
        source="activity_plan.costs"
        sections={["Costs"]}
        fullWidth
        visible={visible}
      />

      <LocalPartnersField source="local_partners" fullWidth visible={visible} />
    </FormTab>
  );
};
export default ProjectTab2;
