import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  required,
} from "react-admin";
import { Group, GroupItem, TimeInput } from "ra-friendsofbabba";

import DayOfWeekInput from "../../DayOfWeekInput";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
}));
const WorkCalendar = ({ field, values, translate, ...props }) => {
  const classes = useStyles();
  const display = { lg: 3, md: 4, sm: 4, xs: 12 };
  const additionalProps = {};
  if (field.is_required) {
    additionalProps.validate = required();
  }
  return (
    <ArrayInput
      {...props}
      {...additionalProps}
      source={field.code}
      label={field.name}
      helperText={translate("resources.posts.fields.business_time.help")}
    >
      <SimpleFormIterator
        disableReordering
        TransitionProps={{ enter: false, exit: false }}
      >
        <FormDataConsumer>
          {({ formData, scopedFormData, getSource, ...rest }) => (
            <Group {...rest}>
              <GroupItem {...display}>
                <DayOfWeekInput
                  label={translate(
                    "resources.posts.fields.business_time.day_of_week"
                  )}
                  source={getSource("day_of_week")}
                  validate={required()}
                  className={classes.input}
                />
              </GroupItem>
              <GroupItem {...display}>
                <TimeInput
                  label={translate(
                    "resources.posts.fields.business_time.start"
                  )}
                  source={getSource("start")}
                  validate={required()}
                  className={classes.input}
                />
              </GroupItem>
              <GroupItem {...display}>
                <TimeInput
                  label={translate("resources.posts.fields.business_time.end")}
                  source={getSource("end")}
                  validate={required()}
                  className={classes.input}
                />
              </GroupItem>
            </Group>
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default WorkCalendar;
