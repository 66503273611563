import {
  ExecutiveProjectAccountableInput,
  ExecutiveProjectCivilOperatorInput,
  ExecutiveProjectCivilReferentInput,
  ExecutiveProjectOperationalContactInput,
  ExecutiveProjectPrivateInput,
  PublicSpaceHandlerInput,
} from "../../input";
import {
  ExecutiveProjectHumanResourcesField,
  ExecutiveProjectLocalPartnersField,
} from "../../field";
import {
  FormTab,
  Group,
  GroupItem,
  ValidationSummary,
  useTabVisibility,
} from "ra-friendsofbabba";

import DirtyFormAlert from "../../alert/DirtyFormAlert";
import React from "react";

const ExecutiveProjectTab1 = ({ fieldLabel, ...props }) => {
  const visible = useTabVisibility(1);

  return (
    <FormTab {...props} label={fieldLabel("tabs.section_1")}>
      {visible && <ValidationSummary />}
      {visible && <DirtyFormAlert />}
      <Group fullWidth>
        <GroupItem lg={12} md={12} sm={12} xs={12}>
          <PublicSpaceHandlerInput
            fullWidth
            disabled
            source="project.public_space.handler"
          />
        </GroupItem>
      </Group>
      <ExecutiveProjectPrivateInput
        fullWidth
        source="private"
        fieldLabel={fieldLabel}
      />
      {/**
       * Perché gli ho dato suffisso "*_list"?
       *
       * A causa di un conflitto nei fields gestiti da react-final-form,
       * successivamente al caricamento del from lo stesso engine indica
       * subito come "sporco" il campo "executive_project_local_partners" pocihé
       * lo stesso è pre-caricato mediante contain nel record principale ma,
       * allo stesso tempo, il componente presente di seguito ne esegue un
       * ulteriore caricamento per gestire una lista associata.
       *
       * Per evitare problemi lo stesso oggetto è stato rinominato in
       * "executive_project_local_partners_list" in modo tale da evitare
       * conflitti con il campo "executive_project_local_partners" che
       * è utilizzato in altra sede (gestione Budget) i un ArrayInput per
       * editarne ulteriori informazioni.
       */}
      {visible && (
        <ExecutiveProjectLocalPartnersField
          source="executive_project_local_partners_list"
          fieldLabel={fieldLabel}
        />
      )}
      <ExecutiveProjectAccountableInput
        fullWidth
        source="accountable"
        fieldLabel={fieldLabel}
      />
      <ExecutiveProjectOperationalContactInput
        fullWidth
        source="operational_contact"
        fieldLabel={fieldLabel}
      />
      <ExecutiveProjectCivilReferentInput
        fullWidth
        source="civil_referent"
        fieldLabel={fieldLabel}
      />
      <ExecutiveProjectCivilOperatorInput
        fullWidth
        source="civil_operator"
        fieldLabel={fieldLabel}
      />
      {visible && (
        <ExecutiveProjectHumanResourcesField
          fullWidth
          source="executive_project_human_resources"
          fieldLabel={fieldLabel}
        />
      )}
    </FormTab>
  );
};
export default ExecutiveProjectTab1;
