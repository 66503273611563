import {
  Group,
  GroupItem,
  GroupTitle,
  LongTextField,
  ReferenceListField,
  TextField,
  useWorkflowInput,
} from "ra-friendsofbabba";

import useFormPermission from "../input/useFormPermission";
import { useMemo } from "react";

const ExecutiveProjectLocalPartnersField = ({
  visible,
  fieldLabel,
  ...props
}) => {
  const fieldSource = props?.source?.replace("_list", "");
  const { disabled } = useWorkflowInput({
    ...props,
    source: fieldSource,
  });
  const hasFormPermission = useFormPermission();
  const hasPermission = useMemo(
    () => hasFormPermission(fieldSource),
    [hasFormPermission, fieldSource]
  );
  return (
    <Group wrapper>
      <GroupTitle title={fieldLabel(fieldSource)} />
      <Group {...props}>
        <GroupItem lg={12} md={12} sm={12}>
          <ReferenceListField
            tab={1}
            sort={{ field: "id", order: "ASC" }}
            addLabel={false}
            createLabel="ra.action.add"
            reference="executive-project-local-partners"
            target="executive_project_id"
            mobileBreakpoint="sm"
            mobilePrimaryText={"name"}
            mobileSecondaryText="headquarters"
            create={!disabled && hasPermission}
            modify={!disabled && hasPermission}
            remove={!disabled && hasPermission}
          >
            <TextField source="name" />
            <LongTextField source="headquarters" />
            <LongTextField source="roles" />
          </ReferenceListField>
        </GroupItem>
      </Group>
    </Group>
  );
};
export default ExecutiveProjectLocalPartnersField;
