import {
  DebouncedTextInput,
  Group,
  GroupItem,
  Toolbar,
  useSaveMutation,
} from "ra-friendsofbabba";
import { SimpleForm, required } from "react-admin";

const ExecutiveProjectPublicSpaceForm = (props) => {
  const save = useSaveMutation({ ...props });

  return (
    <SimpleForm
      {...props}
      toolbar={
        <Toolbar
          backTab={2}
          backReferenceTarget="executive_project_id"
          backReference="executive-projects"
        />
      }
      save={save}
    >
      <Group fullWidth>
        <GroupItem lg={8} md={10} sm={12}>
          <DebouncedTextInput
            source="name"
            maxLength={200}
            validate={required()}
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={10} md={12} sm={12}>
          <DebouncedTextInput
            source="address"
            maxLength={200}
            validate={required()}
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={10} md={12} sm={12}>
          <DebouncedTextInput
            source="owner"
            maxLength={200}
            validate={required()}
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={10} md={12} sm={12}>
          <DebouncedTextInput
            source="planned_activities"
            maxLength={1000}
            multiline
            validate={required()}
          />
        </GroupItem>
      </Group>
    </SimpleForm>
  );
};
export default ExecutiveProjectPublicSpaceForm;
