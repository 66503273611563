import { FieldTitle, useInput } from "react-admin";
import { FormControl, FormLabel, makeStyles } from "@material-ui/core";
import { TreeItem, TreeView } from "../mui";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import React from "react";
import { useEffect } from "react";

const useViewStyles = makeStyles({
  root: {},
});

const useItemStyles = makeStyles((theme) => ({
  root: {
    "& > .MuiTreeItem-content > .MuiTreeItem-label": {
      display: "flex",
      alignItems: "center",
      padding: "4px 0",
      background: "transparent !important",
      pointerEvents: "none",
    },
    "& > .MuiTreeItem-content  > .MuiTreeItem-label::before": {
      content: "''",
      display: "inline-block",
      width: 18,
      height: 18,
      marginRight: 8,
      marginLeft: 8,
      borderRadius: 3,
      border: "2px solid #858281",
      background: "white",
    },
    "& .MuiTreeItem-group": {
      marginLeft: 24,
    },
  },
  label: {
    padding: 0,
  },
  selected: {
    "& > .MuiTreeItem-content  > .MuiTreeItem-label::before": {
      background: theme.palette.primary.main,
      border: "1px solid transparent",
    },
  },
}));
const useRootStyles = makeStyles((theme) => ({
  label: {
    transform: "translate(0, 8px) scale(0.75)",
    transformOrigin: `top ${theme.direction === "ltr" ? "left" : "right"}`,
    marginBottom: theme.spacing(1),
  },
}));

const TreeNode = ({ nodeId, children, parentId, classes, ...props }) => (
  <TreeItem {...props} key={nodeId} nodeId={nodeId} classes={classes}>
    {children?.map((child) => (
      <TreeNode key={child.nodeId} {...child} classes={classes} />
    ))}
  </TreeItem>
);
const defaultParse = (values) => values?.map(({ id }) => id.toString());
const defaultFormat = (values) => values?.map((id) => ({ id }));
const TreeCheckboxInput = ({
  nodes,
  parse = defaultParse,
  format = defaultFormat,
  expandSelected = true,
  onChange,
  ...props
}) => {
  const {
    input: { onChange: internalOnChange, value },
  } = useInput(props);
  const classesRoot = useRootStyles();
  const classesView = useViewStyles();
  const classesItem = useItemStyles();
  const selected = parse(value || []);
  const [nodeList, setNodeList] = React.useState([]);
  const [expanded, setExpanded] = React.useState(
    expandSelected ? selected : []
  );
  useEffect(() => {
    const parsedValues = parse(value || []);
    const matchingValues = expanded.filter((id) => parsedValues.includes(id));
    if (matchingValues.length !== parsedValues.length && expandSelected) {
      setExpanded(parsedValues);
    }
  }, [value, expanded, expandSelected, parse]);

  useEffect(() => {
    const nodeList = [];
    const flatten = (node) => {
      nodeList.push(node);
      if (node.children) {
        node.children.forEach(flatten);
      }
    };
    nodes.forEach(flatten);
    setNodeList(nodeList);
  }, [nodes]);

  const handleToggle = (event, nodeIds) => {
    if (event.target.nodeName !== "svg") {
      return;
    }
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    if (event.target.nodeName === "svg") {
      return;
    }
    const first = nodeIds[0]?.toString();
    let newSelection;
    if (selected.includes(first)) {
      newSelection = selected.filter((id) => id !== first);
    } else {
      newSelection = [...selected, first];
    }
    // If I'm removing a parent, I have to select all children recursvely
    // and remove them from the selection:
    if (newSelection.length < selected.length) {
      const node = nodeList.find((n) => n.nodeId === first);
      const removeRecursive = (node) => {
        newSelection = newSelection.filter((id) => id !== node.nodeId);
        if (node.children) {
          node.children.forEach(removeRecursive);
        }
      };
      removeRecursive(node);
    }

    // If I'm adding a child, I have to select all parents recursively
    // and add them to the selection:
    if (newSelection.length > selected.length) {
      const node = nodeList.find((n) => n.nodeId === first);
      const addRecursive = (node) => {
        if (node.parentId) {
          newSelection.push(node.parentId);
          const parentNode = nodeList.find((n) => n.nodeId === node.parentId);
          addRecursive(parentNode);
        }
      };
      addRecursive(node);
    }

    if (onChange) {
      onChange(newSelection);
    }

    internalOnChange(format(newSelection));
  };
  return (
    <FormControl>
      <FormLabel component="legend" className={classesRoot.label}>
        <FieldTitle
          label={props?.label}
          source={props?.source}
          resource={props?.resource}
          isRequired={props?.isRequired}
        />
      </FormLabel>
      <TreeView
        classes={classesView}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        multiSelect
      >
        {nodes.map((node) => (
          <TreeNode key={node.nodeId} {...node} classes={classesItem} />
        ))}
      </TreeView>
    </FormControl>
  );
};

const nodeShape = PropTypes.shape({
  parentId: PropTypes.string,
  nodeId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});
nodeShape.children = PropTypes.arrayOf(nodeShape);
TreeCheckboxInput.defaultProps = {
  nodes: PropTypes.arrayOf(nodeShape).isRequired,
  /**
   * Indicates if the node should be expanded when selected
   */
  expandSelected: PropTypes.bool,
};

export default TreeCheckboxInput;
