import { Alert, AlertTitle } from "ra-friendsofbabba";
import { Collapse, Typography } from "@material-ui/core";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFormState } from "react-final-form";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
}));
const DirtyFormAlert = ({ message = undefined }) => {
  const { dirty, dirtyFields } = useFormState({
    subscription: { dirty: true, dirtyFields: true },
  });
  const translate = useTranslate();
  const classes = useStyles();
  // if env is development log dirtyFields
  if (process.env.NODE_ENV === "development") {
    console.log("dirtyFields", dirtyFields);
  }
  return (
    <Collapse in={dirty} className={classes.root}>
      <Alert severity="warning" elevation={1}>
        <AlertTitle>{translate("ra.title.dirty_form")}</AlertTitle>
        <Typography variant="body2">
          {message ? translate(message) : translate("ra.message.dirty_form")}
        </Typography>
      </Alert>
    </Collapse>
  );
};
export default DirtyFormAlert;
