import {
  DebouncedTextInput,
  Group,
  GroupItem,
  GroupTitle,
  useWorkflowInput,
} from "ra-friendsofbabba";
import React, { useMemo } from "react";

import { email } from "react-admin";
import useFormPermission from "./useFormPermission";

const ExecutiveProjectCivilReferentInput = ({ fieldLabel, ...props }) => {
  const { disabled } = useWorkflowInput(props);
  const visible = useMemo(() => props?.record?.is_civil_service_ready, [props]);
  const hasFormPermission = useFormPermission();
  const hasPermission = useMemo(
    () => hasFormPermission(props?.source),
    [hasFormPermission, props?.source]
  );
  if (!visible) {
    return null;
  }
  return (
    <Group {...props} disabled={disabled || !hasPermission} wrapper>
      <GroupTitle
        title={fieldLabel("civil_referent")}
        subTitle={fieldLabel("civil_referent.help")}
      />
      <Group>
        <GroupItem lg={5}>
          <DebouncedTextInput source="civil_referent_name" maxLength={50} />
        </GroupItem>
        <GroupItem lg={5}>
          <DebouncedTextInput source="civil_referent_phone" maxLength={30} />
        </GroupItem>
        <GroupItem lg={5}>
          <DebouncedTextInput
            source="civil_referent_email"
            type="email"
            maxLength={100}
            validate={email()}
          />
        </GroupItem>
      </Group>
    </Group>
  );
};
export default ExecutiveProjectCivilReferentInput;
