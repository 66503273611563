import React from "react";
import { SelectInput } from "react-admin";

const PHASES = [1, 2].map((id) => ({
  id,
  name: `resources.phases.data.${id}`,
}));

const PhaseInput = (props) => <SelectInput {...props} choices={PHASES} />;

export default PhaseInput;
