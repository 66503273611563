import { DebouncedTextInput, Group, GroupItem } from "ra-friendsofbabba";

import Disposition from "./Disposition";

const Money = ({ translate, ...props }) => {
  return (
    <Group wrapper {...props}>
      <Group>
        <GroupItem lg={4} md={4} sm={8} xs={12}>
          <DebouncedTextInput source="config.currency" label="Currency" />
        </GroupItem>
      </Group>
      <Disposition />
    </Group>
  );
};
export default Money;
