import {
  Alert,
  AlertTitle,
  BooleanInput,
  DebouncedTextInput,
  Group,
  GroupItem,
  GroupTitle,
  useFieldLabel,
  useWorkflowInput,
} from "ra-friendsofbabba";
import { Collapse, Divider, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { maxValue, useTranslate } from "ra-core";
import { useForm, useFormState } from "react-final-form";

import DayOfWeekInput from "./DayOfWeekInput";
import { Fragment } from "react";
import MoneyInput from "./MoneyInput";
import WorkingHoursInput from "./WorkingHoursInput";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import useFormPermission from "./useFormPermission";

const formatter = Intl.NumberFormat("it-IT", {
  style: "currency",
  currency: "EUR",
});
const ALL_COSTS = ["execution", "staff", "supplies", "promotion", "additional"];
const MAX_AMOUNT = 50000;
const validateSuppliesCost = (value, values) => {
  const totalAmount = ALL_COSTS.reduce((acc, cur) => {
    return acc + get(values, `activity_plan.${cur}_cost`, 0);
  }, 0);
  const maxAmount = totalAmount * 0.1;
  if (value > maxAmount) {
    return {
      message: "ra.validation.supplies_cost_too_high",
      args: { maxAmount: formatter.format(maxAmount) },
    };
  }
};
const maxValueValidator = maxValue(MAX_AMOUNT);
const CostItem = ({ source, fieldSource, fieldLabel, ...props }) => {
  const { disabled } = useWorkflowInput({
    ...props,
    source: "activity_plan.costs",
  });
  const hasFormPermission = useFormPermission();
  const hasCostsPermission = useMemo(
    () => hasFormPermission("activity_plan.costs"),
    [hasFormPermission]
  );
  const validate =
    source === "supplies"
      ? [validateSuppliesCost, maxValueValidator]
      : maxValueValidator;

  return (
    <Group {...props} fullWidth>
      <GroupItem lg={4} md={4} sm={6} xs={12}>
        <MoneyInput
          source={fieldSource(`${source}_cost`)}
          label={fieldLabel(`${source}_cost`)}
          helperText={fieldLabel(`${source}_cost.help`)}
          validate={validate}
          disabled={disabled || !hasCostsPermission}
          isRequired
        />
      </GroupItem>
      <GroupItem lg={8} md={8} sm={6} xs={12}>
        <DebouncedTextInput
          source={fieldSource(`${source}_desc`)}
          label={fieldLabel(`${source}_desc`)}
          maxLength={200}
          multiline
          disabled={disabled || !hasCostsPermission}
          isRequired
        />
      </GroupItem>
    </Group>
  );
};
const useCostTotalStyles = makeStyles((theme) => ({
  label: {
    padding: theme.spacing(1),
  },
  value: {
    padding: theme.spacing(1),
  },
}));
const CostTotal = () => {
  const translate = useTranslate();
  const { values } = useFormState({ subscription: { values: true } });
  const classes = useCostTotalStyles();
  const total = useMemo(
    () =>
      ALL_COSTS.reduce((acc, cur) => {
        const value = get(values, `activity_plan.${cur}_cost`, 0);
        if (typeof value === "string") {
          return acc + parseFloat(value);
        }
        return acc + value;
      }, 0) || 0,
    [values]
  );
  return (
    <Group>
      <GroupItem lg={3} md={3} sm={3} xs={12}>
        <Typography variant="subtitle1" className={classes.label}>
          {translate("resources.activity-plans.total_cost")}
        </Typography>
        <Divider />
        <Typography variant="h6" className={classes.value}>
          {formatter.format(total)}
        </Typography>
      </GroupItem>
      <GroupItem lg={9} md={9} sm={9} xs={12}>
        <Collapse in={total > MAX_AMOUNT}>
          <Alert severity="warning">
            <AlertTitle>
              {translate("resources.activity-plans.warn.total_too_hight.title")}
            </AlertTitle>
            <Typography variant="body2">
              {translate(
                "resources.activity-plans.warn.total_too_hight.message",
                {
                  totalAmount: formatter.format(total),
                  maxAmount: formatter.format(MAX_AMOUNT),
                }
              )}
            </Typography>
          </Alert>
        </Collapse>
      </GroupItem>
    </Group>
  );
};

const CostsGroup = ({ fieldSource, fieldLabel, ...props }) => {
  const { disabled } = useWorkflowInput({
    ...props,
    source: "activity_plan.costs",
  });

  return (
    <Group wrapper {...props}>
      <GroupTitle
        title={fieldLabel("costs")}
        subTitle={fieldLabel("costs.help")}
      />
      {ALL_COSTS.map((source) => (
        <CostItem
          key={source}
          source={source}
          fieldSource={fieldSource}
          fieldLabel={fieldLabel}
          disabled={disabled}
        />
      ))}
      <CostTotal />
    </Group>
  );
};

const usePlanGroupStyles = makeStyles((theme) => ({
  longLabelInput: {
    "& label": {
      lineHeight: 1.15,
    },
    "& textarea": {
      marginTop: theme.spacing(2),

      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(4),
      },
    },
  },
}));
const PlanGroup = ({ fieldSource, fieldLabel, ...props }) => {
  const { disabled } = useWorkflowInput({
    ...props,
    source: "activity_plan.plan",
  });
  const hasFormPermission = useFormPermission();
  const hasPlanPermission = useMemo(
    () => hasFormPermission("activity_plan.plan"),
    [hasFormPermission]
  );
  const classes = usePlanGroupStyles();
  return (
    <Group wrapper {...props}>
      <GroupTitle title={fieldLabel("plan")} />

      <Group fullWidth>
        <GroupItem lg={10} md={12} sm={12}>
          <DebouncedTextInput
            label={fieldLabel("description_of_context")}
            source={fieldSource("description_of_context")}
            helperText={fieldLabel("description_of_context.help")}
            maxLength={2000}
            multiline
            disabled={disabled || !hasPlanPermission}
            isRequired
          />
        </GroupItem>
        <GroupItem lg={10} md={12} sm={12}>
          <DebouncedTextInput
            className={classes.longLabelInput}
            label={fieldLabel("description_of_plan")}
            source={fieldSource("description_of_plan")}
            helperText={fieldLabel("description_of_plan.help")}
            maxLength={2000}
            multiline
            disabled={disabled || !hasPlanPermission}
            isRequired
          />
        </GroupItem>
      </Group>
    </Group>
  );
};

const CalendarGroup = ({ visible, fieldSource, fieldLabel, ...props }) => {
  const { disabled } = useWorkflowInput({
    ...props,
    source: "activity_plan.calendar",
  });
  const hasFormPermission = useFormPermission();
  const hasCalendarPermission = useMemo(
    () => hasFormPermission("activity_plan.calendar"),
    [hasFormPermission]
  );
  return (
    <Group wrapper {...props}>
      <GroupTitle
        title={fieldLabel("activity_plan_calendar_days_and_hours")}
        subTitle={fieldLabel("activity_plan_calendar_days_and_hours.help")}
      />
      <Group fullWidth>
        <GroupItem>
          <DayOfWeekInput
            label={fieldLabel("number_of_days")}
            source={fieldSource("number_of_days")}
            disabled={disabled || !hasCalendarPermission}
            isRequired
          />
        </GroupItem>
        <GroupItem>
          <WorkingHoursInput
            label={fieldLabel("number_of_hours")}
            source={fieldSource("number_of_hours")}
            disabled={disabled || !hasCalendarPermission}
            isRequired
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={10} md={12} sm={12}>
          <DebouncedTextInput
            label={fieldLabel("description_of_calendar")}
            source={fieldSource("description_of_calendar")}
            helperText={fieldLabel("description_of_calendar.help")}
            maxLength={800}
            multiline
            disabled={disabled || !hasCalendarPermission}
            isRequired
          />
        </GroupItem>
      </Group>
    </Group>
  );
};

const PromotionAndMonitoringGroup = ({
  fieldSource,
  fieldLabel,
  sub = [],
  ...props
}) => {
  const { disabled } = useWorkflowInput({
    ...props,
    source: "activity_plan.promotion_and_monitoring",
  });
  const hasFormPermission = useFormPermission();
  const hasPromotionAndMonitoringPermission = useMemo(
    () => hasFormPermission("activity_plan.promotion_and_monitoring"),
    [hasFormPermission]
  );
  return (
    <Group wrapper {...props}>
      <Group fullWidth>
        {(sub.length === 0 || sub.indexOf("promotion") !== -1) && (
          <GroupItem lg={10} md={12} sm={12}>
            <DebouncedTextInput
              label={fieldLabel("description_of_promotion")}
              source={fieldSource("description_of_promotion")}
              helperText={fieldLabel("description_of_promotion.help")}
              maxLength={800}
              multiline
              disabled={disabled || !hasPromotionAndMonitoringPermission}
              isRequired
            />
          </GroupItem>
        )}
        {(sub.length === 0 || sub.indexOf("monitoring") !== -1) && (
          <GroupItem lg={10} md={12} sm={12}>
            <DebouncedTextInput
              label={fieldLabel("description_of_monitoring")}
              source={fieldSource("description_of_monitoring")}
              helperText={fieldLabel("description_of_monitoring.help")}
              maxLength={800}
              multiline
              disabled={disabled || !hasPromotionAndMonitoringPermission}
              isRequired
            />
          </GroupItem>
        )}
      </Group>
    </Group>
  );
};

const CivilServiceGroup = ({ fieldSource, fieldLabel, ...props }) => {
  const { disabled } = useWorkflowInput({
    ...props,
    source: "activity_plan.civil_service",
  });
  const form = useForm();
  const { values } = useFormState({ subscription: { values: true } });
  const isCivilServiceReady = useMemo(
    () => get(values, fieldSource("is_civil_service_ready")),
    [values, fieldSource]
  );
  const hasFormPermission = useFormPermission();
  const hasCivilServicePermission = useMemo(
    () => hasFormPermission("activity_plan.civil_service"),
    [hasFormPermission]
  );
  const didMount = useRef(false);
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    const state = props?.record?.transaction?.state;
    const civilOperator = get(values, fieldSource("civil_operator"));

    // Has requested: civil referent infoes will be retrieved
    // from operative contact.
    // const currentCivilReferent = get(values, fieldSource("civil_referent"));
    if (isCivilServiceReady && civilOperator === null && state === "draft") {
      form.change(
        fieldSource("civil_operator"),
        values?.operational_contact?.fullname
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCivilServiceReady, fieldSource, props?.record]);
  return (
    <Group wrapper {...props}>
      <GroupTitle title={fieldLabel("activity_plan_civil_service")} />
      <Group fullWidth>
        <GroupItem lg={10} md={12} sm={12}>
          <BooleanInput
            source={fieldSource("is_civil_service_ready")}
            label={fieldLabel("is_civil_service_ready")}
            disabled={disabled || !hasCivilServicePermission}
            isRequired
          />
        </GroupItem>
      </Group>
      {isCivilServiceReady && (
        <Group wrapper fullWidth>
          <Group>
            <GroupItem lg={8} md={10} sm={12}>
              <DebouncedTextInput
                label={fieldLabel("civil_referent")}
                source={fieldSource("civil_referent")}
                helperText={fieldLabel("civil_referent.help")}
                maxLength={200}
                disabled={disabled || !hasCivilServicePermission}
                isRequired
              />
            </GroupItem>
            <GroupItem lg={8} md={10} sm={12}>
              <DebouncedTextInput
                label={fieldLabel("civil_operator")}
                source={fieldSource("civil_operator")}
                helperText={fieldLabel("civil_operator.help")}
                maxLength={200}
                disabled={disabled || !hasCivilServicePermission}
                isRequired
              />
            </GroupItem>
            <GroupItem lg={8} md={10} sm={12}>
              <DebouncedTextInput
                label={fieldLabel("civil_experience")}
                source={fieldSource("civil_experience")}
                helperText={fieldLabel("civil_experience.help")}
                maxLength={800}
                multiline
                disabled={disabled || !hasCivilServicePermission}
                isRequired
              />
            </GroupItem>
          </Group>
        </Group>
      )}
    </Group>
  );
};

const ActivityPlanInput = ({
  sections = [],
  sub = [],
  visible,
  source,
  ...props
}) => {
  const fieldLabel = useFieldLabel({ resource: "activity-plans" });
  const fieldSource = useCallback(
    (source) => `${props.source}.${source}`,
    [props.source]
  );
  const baseSource = source.split(".")[0];
  props.source = baseSource;
  return (
    <Fragment>
      {(sections.length === 0 || sections.indexOf("Plan") !== -1) && (
        <PlanGroup
          {...props}
          fieldLabel={fieldLabel}
          fieldSource={fieldSource}
        />
      )}
      {(sections.length === 0 || sections.indexOf("Costs") !== -1) && (
        <CostsGroup
          {...props}
          fieldLabel={fieldLabel}
          fieldSource={fieldSource}
        />
      )}
      {(sections.length === 0 || sections.indexOf("Calendar") !== -1) && (
        <CalendarGroup
          {...props}
          fieldLabel={fieldLabel}
          fieldSource={fieldSource}
        />
      )}
      {(sections.length === 0 ||
        sections.indexOf("PromotionAndMonitoring") !== -1) && (
        <PromotionAndMonitoringGroup
          {...props}
          sub={sub}
          fieldLabel={fieldLabel}
          fieldSource={fieldSource}
        />
      )}
      {(sections.length === 0 || sections.indexOf("CivilService") !== -1) && (
        <CivilServiceGroup
          {...props}
          fieldLabel={fieldLabel}
          fieldSource={fieldSource}
        />
      )}
    </Fragment>
  );
};

export default ActivityPlanInput;
