import { Create, Edit, useTranslate } from "react-admin";

import { LocalPartnerForm } from "../component/form";

const LocalPartnerTitle = ({ record }) => {
  const translate = useTranslate();
  const prefix = translate("resources.local-partners.name", {
    smart_count: 1,
  });
  return `${prefix} ${record?.name}`;
};
const LocalPartnerCreate = (props) => (
  <Create {...props}>
    <LocalPartnerForm />
  </Create>
);

const LocalPartnerEdit = (props) => (
  <Edit {...props} title={<LocalPartnerTitle />}>
    <LocalPartnerForm />
  </Edit>
);

const config = {
  create: LocalPartnerCreate,
  edit: LocalPartnerEdit,
};
export default config;
