import * as Icons from "@material-ui/icons";

import {
  CrudContext,
  Menu as FobMenu,
  MenuGroup,
  MenuItem,
} from "ra-friendsofbabba";
import React, { useContext, useMemo } from "react";
import { usePermissions, useQuery, useTranslate } from "react-admin";

import { APP_URL } from "../config";

const getBadges = (data) =>
  data
    ? Object.keys(data).reduce(
        (badges, k) =>
          data[k].badge != null ? { ...badges, [k]: data[k].badge } : badges,
        {}
      )
    : {};

const Menu = (props) => {
  const { data } = useContext(CrudContext);
  const badges = useMemo(() => getBadges(data), [data]);
  const { loaded, permissions } = usePermissions();
  const translate = useTranslate();
  const { data: pages } = useQuery({
    resource: "pages/menu",
    type: "get",
  });
  const items = useMemo(
    () =>
      pages?.data?.map((page) => ({
        name: page.name,
        path: `/pages/${page.id}/show`,
        label: page.name,
        icon: Icons.Info,
        group: "pages",
        localize: false,
      })),
    [pages]
  );
  const isUser = loaded && permissions(["user"]);
  return (
    <FobMenu
      {...props}
      order={{
        dashboard: 10,
        contents: 15,
        posts: 20,
        pages: 30,
        admin: 100,
      }}
      items={isUser ? items : []}
      badges={badges}
    >
      {isUser && (
        <MenuGroup label={translate("menu.groups.links")}>
          <MenuItem
            icon={Icons.WebSharp}
            label={translate("menu.items.site")}
            sub={translate("menu.items.site.sub")}
            href="https://www.regione.puglia.it/web/politiche-giovanili/-/galattica?redirect=%2Fweb%2Fpolitiche-giovanili"
            target="_blank"
          />
          <MenuItem
            icon={Icons.Help}
            label={translate("menu.items.help")}
            href="mailto:assistenza.galattica@regione.puglia.it"
            sub={translate("menu.items.help.sub")}
          />
          <MenuItem
            icon={Icons.PictureAsPdf}
            label={translate("menu.items.help.pdf")}
            sub={translate("menu.items.help.pdf.sub")}
            href={`${APP_URL}assets/manuale.pdf`}
            target="_blank"
          />
          <MenuItem
            icon={Icons.Accessibility}
            label={translate("menu.items.help.accessibility")}
            href={
              "https://form.agid.gov.it/view/40c3114e-d956-4af8-a07b-396dbef79704/"
            }
            target="_blank"
          />
        </MenuGroup>
      )}
    </FobMenu>
  );
};

export default Menu;
