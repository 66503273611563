import { CheckboxGroupInput, SelectInput, required } from "react-admin";
import { DebouncedNumberInput, Group, GroupItem } from "ra-friendsofbabba";

import Disposition from "./Disposition";
import { useFormState } from "react-final-form";

const Types = ["Text", "HTML"].map((id) => ({ id, name: id }));
const Toolbar = [
  "italic",
  "bold",
  "underline",
  "align",
  "image",
  "list",
  "blockquote",
  "code-block",
].map((id) => ({
  id,
  name: id,
}));

const Text = ({ translate, ...props }) => {
  const { values } = useFormState({ subscription: { values: true } });
  return (
    <Group wrapper {...props}>
      <Group>
        <GroupItem lg={4} md={4} sm={8} xs={12}>
          <SelectInput
            source="config.type"
            label="Type"
            choices={Types}
            validate={required()}
          />
        </GroupItem>
        {values?.config?.type === "HTML" && (
          <GroupItem lg={12} md={12} sm={12} xs={12}>
            <CheckboxGroupInput
              source="config.toolbar"
              label="Toolbar"
              choices={Toolbar}
            />
          </GroupItem>
        )}
        {values?.config?.type === "Text" && (
          <GroupItem lg={4} md={4} sm={8} xs={12}>
            <DebouncedNumberInput
              source="config.maxLength"
              label="Max Length"
            />
          </GroupItem>
        )}
      </Group>
      <Disposition />
    </Group>
  );
};
export default Text;
