import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  required,
  useInput,
  useNotify,
} from "react-admin";
import {
  Group,
  GroupItem,
  GroupTitle,
  MediaInput,
  useDebounce,
  useFieldLabel,
} from "ra-friendsofbabba";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Collapse } from "@material-ui/core";
import MunicipalityInput from "./MunicipalityInput";
import ProvinceInput from "./ProvinceInput";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { useFormState } from "react-final-form";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: 0,
    marginTop: 0,
  },
}));
const PublicAuthorityPartnersInput = ({ dep, ...props }) => {
  useInput({ ...props });
  const { values } = useFormState({ subscription: { values: true } });
  const fieldLabel = useFieldLabel({ resource: "public-authority-partners" });
  const classes = useStyles();
  const isGroup = useMemo(() => get(values, dep, false), [values, dep]);
  const didMount = useRef(false);
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const value = get(props.record, props.source);
  const debouncedValue = useDebounce(value, 1000);
  const notify = useNotify();
  const handleRejection = useCallback(
    () => notify("ra.message.file_rejected", { type: "error" }),
    [notify]
  );
  useEffect(() => {
    if (!debouncedValue) {
      return;
    }
    if (didMount.current) {
      return;
    }
    // Perché?
    // Se non forzo il refresh tramite l'aggiornamento del timestamp (collegato
    // alla key del componente ArrayInput), aprendo l'app e seguendo il flow: lista -> visualizza,
    // l'elenco dei comuni partner non è stampato correttamente a causa di un 'undefined' che
    // viene poi trasformato in elenco.
    didMount.current = true;
    setTimestamp(new Date().getTime());
  }, [debouncedValue]);

  return (
    <Collapse in={isGroup}>
      <Group wrapper {...props}>
        <GroupTitle title={fieldLabel("title")} subTitle={fieldLabel("sub")} />
        <Group>
          <GroupItem lg={12} md={12} sm={12}>
            <ArrayInput
              key={timestamp}
              label=""
              source={props.source}
              className={classes.root}
            >
              <SimpleFormIterator disableReordering>
                <FormDataConsumer>
                  {({ getSource, formData, scopedFormData, ...rest }) => (
                    <Group wrapper {...rest}>
                      <Group fullWidth>
                        <GroupItem lg={2} md={3} sm={4} xs={12}>
                          <ProvinceInput
                            label={fieldLabel("province")}
                            source={getSource("province")}
                            validate={required()}
                            disabled={props.disabled}
                          />
                        </GroupItem>
                        <GroupItem lg={6} md={7} sm={8} xs={12}>
                          <MunicipalityInput
                            label={fieldLabel("municipality_id")}
                            source={getSource("municipality_id")}
                            validate={required()}
                            deps={{ province: getSource("province") }}
                            disabled={props.disabled}
                          />
                        </GroupItem>
                      </Group>
                      <Group fullWidth>
                        <GroupItem lg={12} md={12} sm={12} xs={12}>
                          <MediaInput
                            key={getSource("media")}
                            label={fieldLabel("media")}
                            source={getSource("media")}
                            helperText={fieldLabel("media.help")}
                            title="filename"
                            accept=".pdf,.p7m"
                            disabled={props.disabled}
                            validate={required()}
                            record={props.disabled ? formData : rest?.record}
                            options={{
                              onDropRejected: handleRejection,
                            }}
                          />
                        </GroupItem>
                      </Group>
                    </Group>
                  )}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </GroupItem>
        </Group>
      </Group>
    </Collapse>
  );
};
export default PublicAuthorityPartnersInput;
