import { DebouncedNumberInput } from "ra-friendsofbabba";
import { InputAdornment } from "@material-ui/core";
import React from "react";
import { required } from "react-admin";

const Money = ({ field, values, translate, ...props }) => {
  return (
    <DebouncedNumberInput
      {...props}
      source={field.code}
      label={field.name}
      validate={field?.is_required && required()}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {field?.config?.currency}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Money;
