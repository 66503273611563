import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import React, { useMemo, useState } from "react";

import { Chip } from "@material-ui/core";
import { Fragment } from "react";
import MapSearchInput from "../../MapSearchInput";
import RoomIcon from "@material-ui/icons/Room";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import { divIcon } from "leaflet";
import { get } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { renderToString } from "react-dom/server";
import { useCallback } from "react";
import { useForm } from "react-final-form";

const useStyles = makeStyles((theme) => ({
  map: {
    height: 400,
    marginBottom: theme.spacing(1),
    width: "100%",
    borderRadius: theme.spacing(1),
  },
  llz: {
    float: "right",
    "& .MuiChip-root": {
      marginLeft: theme.spacing(1),
    },
  },
}));
const PositionMarker = ({ position, onClick }) => {
  useMapEvents({
    click(e) {
      onClick(e);
    },
  });
  const icon = divIcon({
    className: `transparent`,
    html: renderToString(
      <RoomIcon fontSize="large" style={{ fontSize: "80px !important" }} />
    ),
    iconAnchor: [40, 70],
  });
  return position === null ? null : <Marker position={position} icon={icon} />;
};

const Map = ({
  field,
  values,
  translate,
  fullWidth,
  center = [40.842692, 17.055715],
  defaultZoom = 16,
  ...props
}) => {
  const classes = useStyles();
  const form = useForm();
  const [map, setMap] = useState(null);

  const { lat, lng, zoom } = useMemo(
    () => ({
      lat: get(values, `${field.code}.lat`, center[0]) || center[0],
      lng: get(values, `${field.code}.lng`, center[1]) || center[1],
      zoom: get(values, `${field.code}.zoom`, defaultZoom) || defaultZoom,
    }),
    [values, field, center, defaultZoom]
  );

  const handleClick = useCallback(
    (position) => {
      form.batch(() => {
        form.change(`${field.code}.lat`, position.latlng.lat);
        form.change(`${field.code}.lng`, position.latlng.lng);
        form.change(`${field.code}.zoom`, zoom);
      });
    },
    [form, field, zoom]
  );

  const handleChange = useCallback(
    (position) => {
      form.batch(() => {
        form.change(`${field.code}.lat`, position.x);
        form.change(`${field.code}.lng`, position.y);
        form.change(`${field.code}.zoom`, zoom);
        form.change(`${field.code}.keyword`, position.label);
        map?.flyTo([position.y, position.x], zoom);
      });
    },
    [form, field, map, zoom]
  );
  return (
    <Fragment>
      <MapSearchInput
        {...props}
        label={field.name}
        source={`${field.code}.keyword`}
        fullWidth
        onChange={handleChange}
      />
      <MapContainer
        key={values?.modified}
        center={[lat, lng]}
        zoom={zoom}
        className={classes.map}
        whenCreated={setMap}
        scrollWheelZoom={false}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <PositionMarker position={[lat, lng]} onClick={handleClick} />
      </MapContainer>
      {lat && lng && (
        <div className={classes.llz}>
          <Chip label={lat} />
          <Chip label={lng} />
          <Chip icon={<ZoomInIcon />} label={zoom} />
        </div>
      )}
    </Fragment>
  );
};

export default Map;
