import {
  DebouncedNumberInput,
  DebouncedTextInput,
  Group,
  GroupItem,
  GroupTitle,
} from "ra-friendsofbabba";

import { InputAdornment } from "@material-ui/core";
import React from "react";

const validateTargetSurface = (value, allValues) => {
  if (value > allValues?.public_space?.surface) {
    return "errors.public-spaces.target_surface.invalid";
  }
};
const validateSurface = (value, allValues) => {
  if (value < allValues?.public_space?.target_surface) {
    return "errors.public-spaces.surface.invalid";
  }
};

const PublicSpaceGroup2 = ({
  create,
  visible,
  fieldLabel,
  fieldSource,
  ...props
}) => {
  return (
    <Group wrapper {...props}>
      <GroupTitle title={fieldLabel("descriptions")} />
      <Group fullWidth>
        <GroupItem lg={12} md={12} sm={12}>
          <DebouncedTextInput
            source={fieldSource("description")}
            label={fieldLabel("description")}
            maxLength={500}
            multiline
            isRequired
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={4} md={4} sm={6} xs={6}>
          <DebouncedNumberInput
            label={fieldLabel("surface")}
            source={fieldSource("surface")}
            helperText={fieldLabel("surface.help")}
            validate={validateSurface}
            InputProps={{
              endAdornment: <InputAdornment position="end">mq</InputAdornment>,
            }}
            isRequired
          />
        </GroupItem>
        <GroupItem lg={4} md={4} sm={6} xs={6}>
          <DebouncedNumberInput
            label={fieldLabel("target_surface")}
            source={fieldSource("target_surface")}
            helperText={fieldLabel("target_surface.help")}
            validate={validateTargetSurface}
            InputProps={{
              endAdornment: <InputAdornment position="end">mq</InputAdornment>,
            }}
            isRequired
          />
        </GroupItem>
      </Group>
      <Group fullWidth>
        <GroupItem lg={6} md={6} sm={12}>
          <DebouncedTextInput
            label={fieldLabel("description_of_activities")}
            source={fieldSource("description_of_activities")}
            helperText={fieldLabel("description_of_activities.help")}
            maxLength={500}
            multiline
            isRequired
          />
        </GroupItem>
        <GroupItem lg={6} md={6} sm={12}>
          <DebouncedTextInput
            label={fieldLabel("description_of_environments")}
            source={fieldSource("description_of_environments")}
            helperText={fieldLabel("description_of_environments.help")}
            maxLength={500}
            multiline
            isRequired
          />
        </GroupItem>
        <GroupItem lg={6} md={6} sm={12}>
          <DebouncedTextInput
            label={fieldLabel("description_of_activities_comp")}
            source={fieldSource("description_of_activities_comp")}
            helperText={fieldLabel("description_of_activities_comp.help")}
            maxLength={500}
            multiline
            isRequired
          />
        </GroupItem>
        <GroupItem lg={6} md={6} sm={12}>
          <DebouncedTextInput
            label={fieldLabel("description_of_tools")}
            source={fieldSource("description_of_tools")}
            helperText={fieldLabel("description_of_tools.help")}
            maxLength={500}
            multiline
            isRequired
          />
        </GroupItem>
        <GroupItem lg={6} md={6} sm={12}>
          <DebouncedTextInput
            label={fieldLabel("description_of_motivations")}
            source={fieldSource("description_of_motivations")}
            helperText={fieldLabel("description_of_motivations.help")}
            maxLength={500}
            multiline
            isRequired
          />
        </GroupItem>
      </Group>
    </Group>
  );
};
export default PublicSpaceGroup2;
