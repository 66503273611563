import { useMutation, useRefresh } from "ra-core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { IconButton } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 100,
  },
}));

const MoveButtonItem = ({ record, direction = "up", icon }) => {
  const refresh = useRefresh();
  const [move, { loading }] = useMutation(
    {
      type: "post",
      resource: "post-type-fields/move",
      payload: {
        id: record.id,
        direction,
      },
    },
    {
      onSuccess: () => refresh(),
    }
  );
  const handleMove = (e) => {
    e.stopPropagation();
    e.preventDefault();
    move();
  };
  return (
    <IconButton disabled={loading} onClick={handleMove}>
      {React.cloneElement(icon)}
    </IconButton>
  );
};

const MoveButton = ({ record }) => {
  const classes = useStyles();
  const order = record?.order;
  return (
    <div className={classes.root}>
      <MoveButtonItem
        record={record}
        direction="down"
        icon={<ArrowDropDownIcon />}
      />
      <MoveButtonItem
        record={record}
        direction="up"
        icon={<ArrowDropUpIcon />}
        disabled={order === 0}
      />
    </div>
  );
};
export default MoveButton;
