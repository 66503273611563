import React, { useMemo } from "react";

import { Collapse } from "@material-ui/core";
import { DebouncedTextInput } from "ra-friendsofbabba";
import { useFormState } from "react-final-form";

const BudgetManagementNotesInput = (props) => {
  const { values } = useFormState({ subscription: { values: true } });
  const visible = useMemo(
    () => values?.budget_management_type_id > 1,
    [values]
  );
  return (
    <Collapse in={visible}>
      <DebouncedTextInput multiline maxLength={1000} {...props} />
    </Collapse>
  );
};
export default BudgetManagementNotesInput;
